import React, { useCallback, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { SqError } from 'react-square-payment-form/lib/components/models';
import { useMutation } from 'react-fetching-library';
import { useAlert } from '@chhjit/react-components';

import { useFormState } from 'common/hooks/useFormState/useFormState';
import { createNewPayment } from 'common/api/actions/payment/paymentActions';
import { movingRoutes } from 'bMove/routing/AppRoute';
import { useFormDispatch } from 'common/hooks/useFormDispatch/useFormDispatch';
import { setBookingDoneAndContact } from 'common/context/form/formActionCreators/formActionCreators';
import { useMovingStateContext } from 'bMove/hooks/useMoving/useMoving';

import { Prepayment } from './Prepayment';

export const PrepaymentContainer = () => {
  const { showAlert } = useAlert();
  const { token, appointmentId, locationId, accountId } = useFormState();
  const [isSquareProcessing, setIsSquareProcessing] = useState(false);
  const [hasApiFailed, setHasApiFailed] = useState(false);
  const { moving, prepaymentData } = useMovingStateContext();
  const history = useHistory();
  const dispatch = useFormDispatch();
  const [errors, setErrors] = useState<[SqError] | null>(null);

  const { mutate: createPaymentMutate, loading: isCreatingNewPayment, abort: createPaymentAbort } = useMutation(
    createNewPayment,
  );

  const cardNonceResponseReceived = async (errors: [SqError] | null, nonce: string) => {
    if (errors) {
      setIsSquareProcessing(false);
      setErrors(errors);
      return;
    } else {
      setErrors(null);
      createPaymentAbort();

      if (!locationId || !appointmentId || !accountId || !token || !prepaymentData || !moving?.step5) return;

      const payloadData = {
        locationId,
        accountId,
        appointmentId,
        token,
        amount: prepaymentData.amount.toString(),
        nonce,
        square_location_id: prepaymentData.squareLocationId,
      };

      const { payload } = await createPaymentMutate(payloadData);
      setIsSquareProcessing(false);

      if (payload?.meta.status.code === 200) {
        dispatch(
          setBookingDoneAndContact({
            type: 'email',
            contact: moving.step5.email,
          }),
        );
        history.push(movingRoutes.alldonePrepayment);
      } else if (payload?.meta.errors?.length) {
        setHasApiFailed(true);
        showAlert(payload?.meta.errors[0].message, { variant: 'error' });
      }
    }
  };

  const createVerificationDetails = () => {
    return {
      amount: moving?.step7?.prepaymentValue.toString(),
      currencyCode: 'USD',
      intent: 'CHARGE',
      billingContact: {
        familyName: moving?.step5?.lastName || '',
        givenName: moving?.step5?.firstName || '',
        email: moving?.step5?.email || '',
        country: moving?.step1?.fullAddress.country || '',
        city: moving?.step1?.fullAddress.city || '',
        addressLines: [moving?.step1?.fullAddress.address || ''],
        postalCode: moving?.step1?.fullAddress.postal || '',
        phone: moving?.step5?.phone || '',
      },
    };
  };

  const handleContinueWithoutPrepayment = useCallback(() => {
    if (!moving.step5) {
      return;
    }

    dispatch(
      setBookingDoneAndContact({
        type: 'phone',
        contact: moving.step5.phone,
      }),
    );
    history.push(movingRoutes.alldonePrepaymentError);
  }, [dispatch, history, moving?.step5]);

  const handleSubmit = useCallback(async (submitCallback: () => void) => {
    setIsSquareProcessing(true);
    submitCallback(); // it invokes cardNonceResponseReceived() (by squareContext)
  }, []);

  if (!moving?.step1 || !moving.step5) {
    return <Redirect to={movingRoutes.step7} />;
  }

  return (
    <Prepayment
      errors={errors}
      paymentLoading={isCreatingNewPayment || isSquareProcessing}
      createVerificationDetails={createVerificationDetails}
      cardNonceResponseReceived={cardNonceResponseReceived}
      handleContinueWithoutPrepayment={handleContinueWithoutPrepayment}
      onSubmit={handleSubmit}
      hasApiFailed={hasApiFailed}
    />
  );
};
