import { Action } from 'common/api/types';

import {
  PingPayload,
  PingResponse,
  CreateAccountAddressPayload,
  CreateAccountAddressResponse,
  CreateNewAppointmentPayload,
  CreateNewAppointmentResponse,
  CreateCallRequestPayload,
  CreateCallRequestResponse,
  QuoteRequestParams,
  QuoteRequestResponse,
  CreateAccountAction,
  UpdateAccountAction,
} from './accountActions.types';
import { getTrackingData } from './accountActions.utils';

export const createNewAccountAction = (values: CreateAccountAction.Params): Action<CreateAccountAction.Response> => {
  const { locationId, payload } = values;

  const tracking = getTrackingData();

  const payloadWithTracking = {
    ...payload,
    ...tracking,
  };

  return {
    method: 'POST',
    endpoint: `/public/website/locations/${locationId}/accounts/tokens/generate`,
    body: payloadWithTracking,
  };
};

export const updateAccountAction = (values: UpdateAccountAction.Params): Action<UpdateAccountAction.Response> => {
  const { locationId, accountId, token, payload } = values;

  return {
    method: 'PUT',
    endpoint: `/public/website/locations/${locationId}/accounts/${accountId}/?token=${token}`,
    body: payload,
  };
};

export const pingAction = (values: PingPayload): Action<PingResponse> => {
  const { locationId, accountId, token, booking_complete } = values;

  return {
    method: 'POST',
    endpoint: `/public/website/locations/${locationId}/accounts/${accountId}/ping?token=${token}`,
    body: { booking_complete },
    config: {
      disableInterceptErrors: true,
    },
  };
};

export const createAccountAddressAction = (
  values: CreateAccountAddressPayload,
): Action<CreateAccountAddressResponse> => {
  const { locationId, accountId, token, ...restValues } = values;

  return {
    method: 'POST',
    endpoint: `/public/website/locations/${locationId}/accounts/${accountId}/addresses/?token=${token}`,
    body: { ...restValues.fullAddress, name: restValues.fullAddress.address },
  };
};

export const createAppointmentAction = (values: CreateNewAppointmentPayload): Action<CreateNewAppointmentResponse> => {
  const { locationId, token, ...restValues } = values;

  return {
    method: 'POST',
    endpoint: `/public/website/locations/${locationId}/accounts/${restValues.account.id}/appointments/?token=${token}`,
    body: { ...restValues, type: 'JOB' },
  };
};

export const createCallRequestAction = (values: CreateCallRequestPayload): Action<CreateCallRequestResponse> => {
  const { locationId, accountId, token, ...restValues } = values;

  return {
    method: 'POST',
    endpoint: `/public/website/locations/${locationId}/accounts/${accountId}/calls/requests?token=${token}`,
    body: { ...restValues },
  };
};

export const calculateQuoteAction = (values: QuoteRequestParams): Action<QuoteRequestResponse> => {
  const { locationId, accountId, token, payload } = values;

  return {
    method: 'POST',
    endpoint: `/public/website/locations/${locationId}/accounts/${accountId}/quotes/calculate?token=${token}`,
    body: payload,
    config: {
      disableInterceptErrors: true,
    },
  };
};

export const createNewQuoteAction = (values: QuoteRequestParams): Action<QuoteRequestResponse> => {
  const { locationId, accountId, token, payload } = values;

  return {
    method: 'POST',
    endpoint: `/public/website/locations/${locationId}/accounts/${accountId}/quotes?token=${token}`,
    body: payload,
  };
};
