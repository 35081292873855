import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

export const useStyles = makeStyles({
  index: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    margin: '0 12px 0 4px',
    width: 16,
    height: 16,
    borderRadius: '50%',
    backgroundColor: colors.grey20,
    fontSize: 14,
    lineHeight: '17px',
  },
  text: {
    fontSize: 16,
  },
  textDisabled: {
    color: colors.grey80,
  },
  icon: {
    fontSize: 24,
    marginRight: 8,
  },
  item: {
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    color: colors.grey140,
    textDecoration: 'none',
    cursor: 'default',
  },
  checkedItem: {
    color: colors.secondary.main,
    cursor: 'pointer',
  },
  activeItem: {
    color: colors.primary.main,
  },
  activeIndex: {
    marginRight: 12,
  },
  cursor: {
    cursor: 'pointer',
  },
});
