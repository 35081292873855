import React, { useCallback, useMemo } from 'react';
import { Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { Button } from '@chhjit/react-components';
import reactStringReplace from 'react-string-replace';

import { LoadedImage } from 'common/ui/loadedImage/LoadedImage';
import { HeroImgDecorationSmall } from 'common/assets/icons/HeroImgDecorationSmall';
import { HeroImgDecorationBig } from 'common/assets/icons/HeroImgDecorationBig';
import { useFormState } from 'common/hooks/useFormState/useFormState';

import { useStyles } from './PartnerHero.styles';

export const PartnerHero = () => {
  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { partnerData } = useFormState();

  const handleClick = useCallback(() => {
    // had to manually calculate distance to top, because scrollIntoView was not working on safari,
    // that's probably because element has property scrollTop = 0, which is not true.
    const serviceBoxesDistanceToTop =
      window.pageYOffset + (document.getElementById('service-boxes')?.getBoundingClientRect().top || 0);

    serviceBoxesDistanceToTop && window.scroll({ top: serviceBoxesDistanceToTop, left: 0, behavior: 'smooth' });
  }, []);

  const heroTitle = useMemo(() => {
    return reactStringReplace(partnerData?.heroTitle, /\*(.*?)\*/g, (match, i) => (
      <span key={i} className={styles.importantTitlePart}>
        {match}
      </span>
    ));
  }, [partnerData?.heroTitle, styles.importantTitlePart]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.imgContainer}>
        <div className={styles.imgWrapper}>
          <LoadedImage src={partnerData?.heroImg || ''} alt="partner header" />
        </div>
        {isMobile && <HeroImgDecorationSmall className={styles.imgDecorationSmall} />}
        {isDesktop && <HeroImgDecorationBig className={styles.imgDecorationBig} />}
      </div>

      <div>
        <Typography variant="h3" className={styles.title}>
          {heroTitle}
        </Typography>

        <Button onClick={handleClick} fullWidth size="large" buttonType="twoTone" className={styles.button}>
          Book now
        </Button>
      </div>
    </div>
  );
};
