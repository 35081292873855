import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    cursor: 'pointer',

    '& label': {
      cursor: 'pointer',
    },
  },
  dropdown: {
    zIndex: theme.zIndex.modal,
    width: ({ dropdownWidth }: { dropdownWidth?: number }) => dropdownWidth,
  },
  dropdownItem: {
    paddingRight: 15,
    whiteSpace: 'normal',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '24px',
    padding: '8px 16px',
    letterSpacing: '0.15px',
    overflow: 'hidden',

    '&:nth-child(even)': {
      backgroundColor: colors.grey10,
    },

    '&:hover': {
      backgroundColor: colors.grey20,
    },
  },
  inactive: {
    display: 'none',
  },
  readOnly: {
    pointerEvents: 'none',
  },
  inputEndAdornment: {
    fontSize: 16,
    color: colors.secondary.dark,
    marginRight: 16,
    marginLeft: 4,

    transformOrigin: 'center center',
    transform: 'translateY(-6px)',
  },
  rotateAdornment: {
    transform: 'translateY(-6px) rotate(180deg)',
  },
}));
