import React, { useMemo } from 'react';
import { FormControl, FormHelperText } from '@material-ui/core';
import { Input } from '@chhjit/react-components';
import { useController } from 'react-hook-form';

import { CheckIcon } from 'common/assets/icons/CheckIcon';

import { useStyles } from './ContactInformationInput.styles';
import { ContactInformationInputProps } from './ContactInformationInput.types';

export const ContactInformationInput = ({
  name,
  label,
  mask,
  inputMode,
  rows,
  control,
  required,
  requiredText,
  rules,
  hasPrefilledData,
}: ContactInformationInputProps) => {
  const styles = useStyles();

  const {
    field,
    fieldState: { error, isTouched },
  } = useController({
    name,
    control,
    rules,
  });

  const touched = useMemo(() => required && (hasPrefilledData || isTouched), [hasPrefilledData, isTouched, required]);

  const helperText = useMemo(() => {
    if (error) {
      return error.message;
    }

    if (required) {
      return requiredText || '*required';
    }

    return '';
  }, [error, required, requiredText]);

  return (
    <FormControl fullWidth error={!!error} className={styles.margin}>
      <Input
        {...field}
        endAdornment={touched && !error && <CheckIcon className={styles.checkIcon} />}
        label={label}
        size="medium"
        variant="outlined"
        error={!!error}
        mask={mask}
        rows={rows}
        multiline={!!rows}
        inputProps={{ inputMode }}
        className={styles.input}
      />
      <FormHelperText className={styles.errorText}>{helperText}</FormHelperText>
    </FormControl>
  );
};
