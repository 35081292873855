import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

export const JunkRemovalIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="56" height="57" viewBox="0 0 56 57" fill="none">
      <g clipPath="url(#clip0)">
        <path
          d="M42.9775 19.6251H35.955V25.6967C35.955 23.417 34.4085 21.5726 32.4953 21.5726H29.0356H14.3262C13.3525 21.5726 12.4131 22.0881 11.783 22.9817L8.15147 28.1025V36.4767C8.15147 38.7565 9.69803 40.6009 11.6112 40.6009H13.341H13.4212C13.7763 42.6286 15.5291 44.1637 17.6714 44.1637C19.8136 44.1637 21.5664 42.64 21.9215 40.6009H22.0934H32.5755H32.6557C33.0109 42.6286 34.7636 44.1637 36.9059 44.1637C39.0481 44.1637 40.8009 42.64 41.156 40.6009H47.9723C49.0263 40.6009 49.9886 39.7302 49.9886 38.5846V26.6475C50 22.7984 46.9184 19.6251 42.9775 19.6251Z"
          fill={colors.primary.light}
        />
        <path
          d="M29.7574 19.8198H20.501C20.1458 19.8198 19.848 19.5563 19.7907 19.2127L19.5616 17.7119C19.5387 17.5516 19.573 17.3797 19.6532 17.2422L22.1964 12.8432C22.3912 12.4995 22.8265 12.3849 23.1702 12.5797L31.0404 17.0933C31.2123 17.1964 31.3383 17.3683 31.3841 17.563C31.4299 17.7578 31.3841 17.964 31.2581 18.1358L30.5478 19.1096C30.5249 19.247 30.4562 19.3731 30.3645 19.4647C30.2385 19.6824 30.0094 19.8198 29.7574 19.8198ZM21.1196 18.3878H29.3106L29.6428 17.9411L23.09 14.1835L21.0279 17.7463L21.1196 18.3878Z"
          fill={colors.secondary.main}
        />
        <path
          d="M20.2718 17.5974L19.573 13.2098L9.97295 14.5959L10.5572 19.0981H19.4585L20.2718 17.5974Z"
          fill={colors.basic.white}
        />
        <path
          d="M19.4585 19.8198H10.5572C10.1906 19.8198 9.89276 19.5564 9.84693 19.1898L9.27414 14.6761C9.22831 14.2866 9.4918 13.9315 9.8813 13.8742L19.4814 12.488C19.8594 12.4308 20.226 12.6942 20.2947 13.0837L20.9936 17.4828C21.0165 17.6432 20.9936 17.7921 20.9134 17.9296L20.1 19.4303C19.9625 19.6709 19.7219 19.8198 19.4585 19.8198ZM11.1873 18.3878H19.0346L19.5272 17.4714L18.9773 14.0117L10.7749 15.2031L11.1873 18.3878Z"
          fill={colors.secondary.main}
        />
        <path
          d="M11.0613 38.8482H9.33142C7.02878 38.8482 5.15 36.6716 5.15 34.0023V25.628C5.15 25.4791 5.19583 25.3302 5.28747 25.2156L8.919 20.0948C9.68655 19.0065 10.8321 18.3879 12.0465 18.3879H30.2156C32.5182 18.3879 34.397 20.5645 34.397 23.2337V31.9403C34.397 32.3412 34.0762 32.6505 33.6867 32.6505C33.2972 32.6505 32.9765 32.3298 32.9765 31.9403V23.2337C32.9765 21.355 31.7392 19.8199 30.227 19.8199H12.035C11.2904 19.8199 10.5801 20.2208 10.0761 20.9196L6.58199 25.8571V34.0023C6.58199 35.8811 7.81924 37.4162 9.33142 37.4162H11.0613C11.4508 37.4162 11.7715 37.737 11.7715 38.1265C11.7715 38.516 11.4508 38.8482 11.0613 38.8482Z"
          fill={colors.primary.main}
        />
        <path
          d="M45.6926 38.8482H38.8648C38.4753 38.8482 38.1546 38.5274 38.1546 38.1379C38.1546 37.7484 38.4753 37.4276 38.8648 37.4276H45.6926C46.3226 37.4276 46.9985 36.9007 46.9985 36.1217V27.3808H33.6638C33.2743 27.3808 32.9536 27.06 32.9536 26.6705V17.1621C32.9536 16.7611 33.2743 16.4404 33.6638 16.4404H40.6863C44.9479 16.4404 48.4191 19.9115 48.4191 24.1731V36.0988C48.4305 37.6224 47.2048 38.8482 45.6926 38.8482ZM34.3856 25.9373H46.9985V24.1731C46.9985 20.6905 44.1689 17.8724 40.6978 17.8724H34.397V25.9373H34.3856Z"
          fill={colors.primary.main}
        />
        <path
          d="M34.6261 42.4109C31.8423 42.4109 29.5855 40.1427 29.5855 37.3703C29.5855 34.598 31.8538 32.3297 34.6261 32.3297C37.4099 32.3297 39.6668 34.598 39.6668 37.3703C39.6668 40.1427 37.4099 42.4109 34.6261 42.4109ZM34.6261 33.7502C32.5984 33.7502 31.0175 35.3426 31.0175 37.3589C31.0175 39.3751 32.5984 40.9675 34.6261 40.9675C36.6538 40.9675 38.2348 39.3751 38.2348 37.3589C38.2348 35.3426 36.6538 33.7502 34.6261 33.7502Z"
          fill={colors.primary.main}
        />
        <path
          d="M15.3916 42.4109C12.6078 42.4109 10.351 40.1427 10.351 37.3703C10.351 34.598 12.6193 32.3297 15.3916 32.3297C18.1754 32.3297 20.4322 34.598 20.4322 37.3703C20.4322 40.1427 18.1754 42.4109 15.3916 42.4109ZM15.3916 33.7502C13.3639 33.7502 11.783 35.3426 11.783 37.3589C11.783 39.3751 13.3639 40.9675 15.3916 40.9675C17.4193 40.9675 19.0002 39.3751 19.0002 37.3589C19.0002 35.3426 17.4193 33.7502 15.3916 33.7502Z"
          fill={colors.primary.main}
        />
        <path
          d="M30.2958 38.8481H19.8136C19.4241 38.8481 19.1033 38.5274 19.1033 38.1379C19.1033 37.7484 19.4241 37.4276 19.8136 37.4276H30.2958C30.6853 37.4276 31.006 37.7484 31.006 38.1379C31.006 38.5274 30.6967 38.8481 30.2958 38.8481Z"
          fill={colors.primary.main}
        />
        <path
          d="M27.764 30.176C27.3745 30.176 27.0538 29.8552 27.0538 29.4657V23.417C27.0538 23.016 27.3745 22.7067 27.764 22.7067C28.1535 22.7067 28.4743 23.0275 28.4743 23.417V29.4657C28.4858 29.8552 28.165 30.176 27.764 30.176Z"
          fill={colors.secondary.main}
        />
        <path
          d="M21.4404 30.176C21.0509 30.176 20.7301 29.8552 20.7301 29.4657V23.417C20.7301 23.016 21.0509 22.7067 21.4404 22.7067C21.8299 22.7067 22.1506 23.0275 22.1506 23.417V29.4657C22.1621 29.8552 21.8413 30.176 21.4404 30.176Z"
          fill={colors.secondary.main}
        />
        <path
          d="M15.1281 30.176C14.7386 30.176 14.4179 29.8552 14.4179 29.4657V23.417C14.4179 23.016 14.7386 22.7067 15.1281 22.7067C15.5176 22.7067 15.8384 23.0275 15.8384 23.417V29.4657C15.8384 29.8552 15.5176 30.176 15.1281 30.176Z"
          fill={colors.secondary.main}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="44.85" height="31.6642" fill="white" transform="translate(5.15 12.488)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
