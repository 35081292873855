import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const MealsDonatedIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="70" height="70" viewBox="0 0 70 70" fill="none" style={{ fill: 'none' }}>
      <path
        d="M50.1725 38.8174C50.0098 39.1735 49.6544 39.4019 49.2629 39.4019H25.1124C24.721 39.4019 24.3655 39.1735 24.2029 38.8174L20.4174 30.5322C20.2968 30.2682 20.2968 29.9649 20.4174 29.701L24.2029 21.4157C24.3655 21.0597 24.721 20.8313 25.1124 20.8313H49.2629C49.6544 20.8313 50.0098 21.0597 50.1725 21.4157L53.958 29.701C54.0785 29.9649 54.0785 30.2682 53.958 30.5322L50.1725 38.8174Z"
        fill="#F0CBA7"
      />
      <path
        d="M30.8839 52.635C30.5646 51.4717 29.8012 50.4803 28.7581 49.8743L19.2152 44.3528C18.8486 44.14 18.4436 44.0016 18.0234 43.9457C17.6032 43.8897 17.1761 43.9172 16.7666 44.0266C16.3571 44.1361 15.9732 44.3253 15.637 44.5834C15.3007 44.8415 15.0188 45.1634 14.8072 45.5307L12.7366 37.8098C12.5011 36.9324 11.9267 36.1845 11.1397 35.7306C10.3527 35.2767 9.41771 35.1541 8.5403 35.3896C7.66289 35.6251 6.91497 36.1995 6.46109 36.9865C6.00721 37.7735 5.88453 38.7085 6.12005 39.5859L9.58018 52.3773C9.8491 53.3798 10.4491 54.2619 11.2826 54.8804L21.7734 62.5829L22.4356 65.0661C22.7204 66.1339 23.8173 66.7684 24.8849 66.4828L32.0531 64.5653C33.1237 64.2789 33.7573 63.1766 33.4658 62.1073L30.8839 52.635Z"
        fill="#F0CBA7"
      />
      <path
        d="M40.6826 63.0809C40.5407 63.6141 40.8575 64.1615 41.3906 64.3041L50.5019 66.7414C51.0358 66.8842 51.5842 66.567 51.7266 66.0331L52.6467 62.5829L63.1375 54.8804C63.9539 54.2546 64.5371 53.3734 64.7939 52.3773L68.2172 39.5859C68.3338 39.1515 68.3637 38.6983 68.3052 38.2523C68.2467 37.8063 68.1009 37.3762 67.8762 36.9865C67.6514 36.5968 67.3521 36.2552 66.9954 35.9812C66.6386 35.7072 66.2314 35.5062 65.797 35.3896C65.3625 35.273 64.9094 35.2431 64.4634 35.3016C64.0173 35.3601 63.5872 35.5059 63.1976 35.7306C62.8079 35.9554 62.4663 36.2546 62.1923 36.6114C61.9183 36.9681 61.7173 37.3754 61.6006 37.8098L59.5393 45.5307C59.3277 45.1634 59.0457 44.8415 58.7095 44.5834C58.3732 44.3253 57.9894 44.1361 57.5798 44.0266C57.1703 43.9172 56.7432 43.8897 56.3231 43.9456C55.9029 44.0016 55.4979 44.14 55.1313 44.3528L45.5884 49.8743C44.5453 50.4803 43.7819 51.4717 43.4626 52.635L40.6826 63.0809Z"
        fill="#F0CBA7"
      />
      <path
        d="M47.9055 18.8313H22.4699L18.2275 28.1166H52.1478L47.9055 18.8313Z"
        stroke="#EA7200"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M47.9055 37.402H22.4699L18.2275 28.1167H52.1478L47.9055 37.402Z"
        stroke="#EA7200"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.2207 28.1167H54.1533"
        stroke="#226937"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M40.9845 31.3008H29.3894L27.2637 28.1167H43.1011L40.9845 31.3008Z"
        stroke="#226937"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M49.4609 64.9726L50.6389 60.5554L61.1297 52.8529C61.9495 52.2286 62.536 51.3472 62.7953 50.3499L66.2186 37.5584C66.3368 37.124 66.3679 36.6704 66.31 36.2239C66.2522 35.7774 66.1066 35.3467 65.8817 34.9567C65.6567 34.5666 65.3568 34.225 64.9993 33.9513C64.6418 33.6776 64.2336 33.4774 63.7984 33.3621V33.3621C62.921 33.1286 61.9869 33.2521 61.2004 33.7057C60.414 34.1593 59.8392 34.906 59.6021 35.7824L57.5407 43.5032"
        stroke="#EA7200"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M38.4263 62.0185L41.4907 50.6074C41.81 49.4441 42.5734 48.4527 43.6165 47.8467L53.1594 42.3252C53.526 42.1124 53.931 41.9741 54.3512 41.9181C54.7713 41.8621 55.1984 41.8896 55.6079 41.9991C56.0175 42.1085 56.4013 42.2977 56.7376 42.5558C57.0738 42.8139 57.3558 43.1358 57.5674 43.5031V43.5031C57.9947 44.2441 58.1107 45.1243 57.8898 45.9507C57.669 46.777 57.1294 47.4821 56.3895 47.9111L48.006 52.7516"
        stroke="#EA7200"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.9142 64.9999L19.7363 60.5827L9.24545 52.8803C8.42561 52.256 7.83906 51.3745 7.5798 50.3772L4.11968 37.5858C4.00151 37.1513 3.97045 36.6978 4.02828 36.2512C4.08611 35.8047 4.23169 35.374 4.45664 34.984C4.68159 34.594 4.98147 34.2523 5.33901 33.9786C5.69655 33.705 6.10468 33.5047 6.53993 33.3895V33.3895C7.41727 33.1559 8.3514 33.2794 9.13787 33.733C9.92433 34.1866 10.4991 34.9333 10.7362 35.8097L12.7976 43.5306"
        stroke="#EA7200"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.9482 62.0461L28.8838 50.635C28.5645 49.4717 27.8012 48.4803 26.7581 47.8743L17.2151 42.3528C16.8485 42.14 16.4435 42.0016 16.0234 41.9457C15.6032 41.8897 15.1761 41.9172 14.7666 42.0267C14.3571 42.1361 13.9732 42.3253 13.6369 42.5834C13.3007 42.8415 13.0187 43.1634 12.8071 43.5307V43.5307C12.5971 43.8998 12.4618 44.3067 12.4092 44.7281C12.3565 45.1495 12.3875 45.5772 12.5004 45.9866C12.6132 46.396 12.8056 46.7792 13.0667 47.1142C13.3278 47.4491 13.6524 47.7293 14.0219 47.9387L22.4053 52.7792"
        stroke="#EA7200"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M35.1875 14.5153V5" stroke="#226937" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M42.6504 14.5153V7.24536"
        stroke="#226937"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.7236 14.5153V7.24536"
        stroke="#226937"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};
