import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    '& .MuiDialog-paper': {
      width: '100vw',
      margin: 0,

      [theme.breakpoints.up('sm')]: {
        maxWidth: 443,
      },
    },
  },
  title: {
    padding: '29px 16px 35px',
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.up('sm')]: {
      paddingLeft: 50,
      paddingRight: 50,
    },

    '& > *': {
      maxWidth: 300,
    },
  },
  icon: {
    color: colors.secondary.main,
    fontSize: 16,
    marginTop: 8,
    marginRight: 8,
    cursor: 'pointer',
  },
  buttons: {
    marginTop: 80,
    marginBottom: 48,
    padding: '0 16px',

    [theme.breakpoints.up('sm')]: {
      padding: '0 50px',

      '&.MuiDialogActions-spacing > :not(:first-child)': {
        marginLeft: 16,
      },
    },

    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',

    '& > *': {
      flexGrow: 1,
    },
  },
}));
