import React, { useCallback } from 'react';
import { useMutation } from 'react-fetching-library';

import { useFormState } from 'common/hooks/useFormState/useFormState';
import { formatPhoneForApi } from 'common/utils/form';
import { updateAccountAction } from 'common/api/actions/account/accountActions';
import { useJunkRemovalContext } from 'common/hooks/useJunkRemoval/useJunkRemoval';
import { junkRemovalActions } from 'common/context/junkRemoval/JunkRemovalContextSlice';
import { useFeatureFlags } from 'common/hooks/use-feature-flags/use-feature-flags';

import { WeWillTextModal } from './WeWillTextModal';
import { WeWillTextModalContainerProps } from './WeWillTextModal.types';

export const WeWillTextModalContainer = ({ open, onSubmit, onClose }: WeWillTextModalContainerProps) => {
  const { accountId, token, locationId, partnerData } = useFormState();
  const [{ junkRemoval }, { junkRemovalDispatch }] = useJunkRemovalContext();

  const { loading, mutate, abort } = useMutation(updateAccountAction);

  const { toggles } = useFeatureFlags();

  const handleSubmit = useCallback(
    async ({ phone }: { phone: string }) => {
      if (!junkRemoval?.step2 || !accountId || !token || !locationId) {
        return;
      }

      const formattedPhone = formatPhoneForApi(phone);

      junkRemovalDispatch(junkRemovalActions.setStep2({ ...junkRemoval.step2, phone: formattedPhone }));

      abort();

      const { payload } = await mutate({
        locationId,
        accountId,
        token,
        payload: {
          phone: formattedPhone,
          ...(toggles.PartnerManagement_DefaultReferralSource &&
            partnerData?.source_id && { source: { id: partnerData.source_id } }),
        },
      });

      if (!payload?.meta.errors?.length) {
        onClose();
        onSubmit();
      }
    },
    [
      junkRemoval,
      accountId,
      token,
      locationId,
      partnerData?.source_id,
      toggles.PartnerManagement_DefaultReferralSource,
      mutate,
      onClose,
      onSubmit,
      abort,
      junkRemovalDispatch,
    ],
  );

  return <WeWillTextModal open={open} isLoading={loading} onClose={onClose} onSubmit={handleSubmit} />;
};
