import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

export const useStyles = makeStyles((theme) => ({
  timePickerContainer: {
    height: 300,
  },
  wrapper: {
    maxWidth: 432,
    [theme.breakpoints.up('md')]: {
      maxWidth: 651,
    },
  },
  sliderContainer: {
    position: 'relative',
    maxWidth: 'calc(100vw - 32px)', //-32px sides paddings

    [theme.breakpoints.up('sm')]: {
      maxWidth: '100%',
    },
  },
  sliderItem: {
    outline: 'none',
  },
  sliderArrow: {
    position: 'absolute',
    left: 'calc(50% - 92px)',
    top: 3,
    transform: 'translateX(-50%)',

    color: colors.primary.main,
    fontSize: 16,
    cursor: 'pointer',
    zIndex: 1,
  },
  sliderArrowRight: {
    left: 'auto',
    right: 'calc(50% - 100px)',
    transform: 'translateX(-50%) rotate(180deg)',
    transformOrigin: 'center center',
  },
}));
