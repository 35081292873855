import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

export const HouseCleanupIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="81" height="80" viewBox="0 0 81 80" style={{ fill: 'none' }} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.4869 7.82504C33.6284 7.81452 33.7695 7.80957 33.9101 7.80957C34.1224 7.80957 34.3185 7.87903 34.4768 7.99646C35.0428 8.1546 35.61 8.42377 36.1707 8.8435L65.5848 30.428C66.0089 30.7392 66.1004 31.3352 65.7892 31.7593C65.478 32.1833 64.882 32.2748 64.4579 31.9636L57.5081 26.8638V65.2699C57.5081 68.1239 55.177 70.4551 52.323 70.4551H14.651C11.7969 70.4551 9.46577 68.1239 9.46577 65.2699V26.8637L2.51589 31.9636C2.09183 32.2748 1.4958 32.1833 1.18462 31.7593C0.873435 31.3352 0.964942 30.7392 1.389 30.428L30.8032 8.84349C31.3638 8.42377 31.9311 8.1546 32.497 7.99646C32.6553 7.87903 32.8514 7.80957 33.0637 7.80957C33.2043 7.80957 33.3454 7.81452 33.4869 7.82504ZM33.4869 9.73782C32.9698 9.7977 32.4627 9.97975 31.9419 10.3704L31.9339 10.3764L11.3705 25.466V65.2699C11.3705 67.0719 12.8489 68.5503 14.651 68.5503H52.323C54.125 68.5503 55.6034 67.0719 55.6034 65.2699V25.4661L35.0319 10.3705C34.511 9.97979 34.004 9.7977 33.4869 9.73782Z"
        fill={colors.primary.light}
      />
      <path
        d="M33.4869 9.73782C32.9698 9.7977 32.4627 9.97975 31.9419 10.3704L31.9339 10.3764L11.3705 25.466V65.2699C11.3705 67.0719 12.8489 68.5503 14.651 68.5503H52.323C54.125 68.5503 55.6034 67.0719 55.6034 65.2699V25.4661L35.0319 10.3705C34.511 9.97979 34.004 9.7977 33.4869 9.73782Z"
        fill={colors.primary.light}
      />
      <path
        d="M24.4913 64.7406H13.6976C11.3696 64.7406 9.46484 62.8358 9.46484 60.5078V20.5078"
        stroke={colors.primary.main}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M1 26.4339L30.418 4.84656C31.2645 4.21164 32.1111 4 32.9577 4"
        stroke={colors.primary.main}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M55.6035 20.5078V41.0369" stroke={colors.primary.main} strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M32.1113 4C32.9579 4 33.8044 4.21164 34.651 4.84656L64.069 26.4339"
        stroke={colors.primary.main}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <g clipPath="url(#clip11)">
        <path
          d="M72.081 44.3701H63.1635V52.0801C63.1635 49.1852 61.1996 46.8431 58.7703 46.8431H54.377H35.6984C34.4619 46.8431 33.269 47.4978 32.4689 48.6325L27.8574 55.1351V65.7691C27.8574 68.664 29.8213 71.0061 32.2507 71.0061H34.4473H34.5491C35.0001 73.5809 37.2258 75.5303 39.9462 75.5303C42.6665 75.5303 44.8922 73.5955 45.3432 71.0061H45.5614H58.8721H58.9739C59.4249 73.5809 61.6506 75.5303 64.3709 75.5303C67.0913 75.5303 69.317 73.5955 69.768 71.0061H78.4235C79.7619 71.0061 80.9839 69.9005 80.9839 68.4458V53.2876C80.9984 48.3997 77.0852 44.3701 72.081 44.3701Z"
          fill={colors.primary.light}
        />
        <path
          d="M55.2938 44.6177H43.5396C43.0886 44.6177 42.7104 44.2831 42.6377 43.8467L42.3467 41.941C42.3176 41.7374 42.3613 41.5192 42.4631 41.3446L45.6926 35.7585C45.9399 35.3221 46.4927 35.1766 46.9291 35.4239L56.923 41.1555C57.1413 41.2864 57.3013 41.5046 57.3595 41.7519C57.4177 41.9992 57.3595 42.2611 57.1994 42.4793L56.2975 43.7158C56.2684 43.8904 56.1811 44.0504 56.0648 44.1668C55.9047 44.4432 55.6138 44.6177 55.2938 44.6177ZM44.3252 42.7993H54.7264L55.1483 42.232L46.8273 37.4605L44.2088 41.9847L44.3252 42.7993Z"
          fill={colors.secondary.main}
        />
        <path
          d="M43.2498 41.7952L42.3624 36.2236L30.1719 37.9838L30.9138 43.7009H42.217L43.2498 41.7952Z"
          fill={colors.primary.light}
        />
        <path
          d="M42.2164 44.6175H30.9132C30.4477 44.6175 30.0695 44.2829 30.0113 43.8174L29.2839 38.0858C29.2257 37.5912 29.5603 37.1402 30.0549 37.0675L42.2455 35.3073C42.7255 35.2346 43.191 35.5691 43.2783 36.0637L44.1657 41.6499C44.1948 41.8535 44.1657 42.0427 44.0639 42.2172L43.031 44.1229C42.8565 44.4284 42.551 44.6175 42.2164 44.6175ZM31.7133 42.7991H41.6781L42.3037 41.6353L41.6054 37.2421L31.1896 38.755L31.7133 42.7991Z"
          fill={colors.secondary.main}
        />
        <path
          d="M31.5532 68.7806H29.3566C26.4326 68.7806 24.0469 66.0167 24.0469 62.6272V51.9932C24.0469 51.804 24.1051 51.6149 24.2214 51.4695L28.8329 44.9669C29.8076 43.5849 31.2623 42.7993 32.8043 42.7993H55.8762C58.8002 42.7993 61.1859 45.5633 61.1859 48.9528V60.0087C61.1859 60.5178 60.7786 60.9106 60.284 60.9106C59.7894 60.9106 59.3821 60.5033 59.3821 60.0087V48.9528C59.3821 46.567 57.811 44.6177 55.8907 44.6177H32.7898C31.8442 44.6177 30.9423 45.1269 30.3022 46.0143L25.8653 52.2841V62.6272C25.8653 65.0129 27.4364 66.9622 29.3566 66.9622H31.5532C32.0478 66.9622 32.4552 67.3696 32.4552 67.8642C32.4552 68.3588 32.0478 68.7806 31.5532 68.7806Z"
          fill={colors.primary.main}
        />
        <path
          d="M75.53 68.7805H66.8599C66.3653 68.7805 65.958 68.3732 65.958 67.8786C65.958 67.384 66.3653 66.9767 66.8599 66.9767H75.53C76.3301 66.9767 77.1884 66.3075 77.1884 65.3183V54.2188H60.2554C59.7608 54.2188 59.3535 53.8114 59.3535 53.3168V41.2426C59.3535 40.7335 59.7608 40.3262 60.2554 40.3262H69.1729C74.5845 40.3262 78.9923 44.734 78.9923 50.1455V65.2892C79.0068 67.224 77.4503 68.7805 75.53 68.7805ZM61.1719 52.3858H77.1884V50.1455C77.1884 45.7232 73.5952 42.1446 69.1874 42.1446H61.1865V52.3858H61.1719Z"
          fill={colors.primary.main}
        />
        <path
          d="M61.4769 73.3045C57.942 73.3045 55.0762 70.4241 55.0762 66.9037C55.0762 63.3833 57.9565 60.5029 61.4769 60.5029C65.0119 60.5029 67.8777 63.3833 67.8777 66.9037C67.8777 70.4241 65.0119 73.3045 61.4769 73.3045ZM61.4769 62.3068C58.9021 62.3068 56.8946 64.3288 56.8946 66.8892C56.8946 69.4495 58.9021 71.4715 61.4769 71.4715C64.0518 71.4715 66.0593 69.4495 66.0593 66.8892C66.0593 64.3288 64.0518 62.3068 61.4769 62.3068Z"
          fill={colors.primary.main}
        />
        <path
          d="M37.0512 73.3045C33.5162 73.3045 30.6504 70.4241 30.6504 66.9037C30.6504 63.3833 33.5307 60.5029 37.0512 60.5029C40.5861 60.5029 43.4519 63.3833 43.4519 66.9037C43.4519 70.4241 40.5861 73.3045 37.0512 73.3045ZM37.0512 62.3068C34.4763 62.3068 32.4688 64.3288 32.4688 66.8892C32.4688 69.4495 34.4763 71.4715 37.0512 71.4715C39.626 71.4715 41.6335 69.4495 41.6335 66.8892C41.6335 64.3288 39.626 62.3068 37.0512 62.3068Z"
          fill={colors.primary.main}
        />
        <path
          d="M55.9783 68.7804H42.6676C42.1729 68.7804 41.7656 68.3731 41.7656 67.8785C41.7656 67.3839 42.1729 66.9766 42.6676 66.9766H55.9783C56.4729 66.9766 56.8802 67.3839 56.8802 67.8785C56.8802 68.3731 56.4874 68.7804 55.9783 68.7804Z"
          fill={colors.primary.main}
        />
        <path
          d="M52.7633 57.768C52.2686 57.768 51.8613 57.3607 51.8613 56.8661V49.1851C51.8613 48.676 52.2686 48.2832 52.7633 48.2832C53.2579 48.2832 53.6652 48.6905 53.6652 49.1851V56.8661C53.6797 57.3607 53.2724 57.768 52.7633 57.768Z"
          fill={colors.secondary.main}
        />
        <path
          d="M44.734 57.768C44.2394 57.768 43.832 57.3607 43.832 56.8661V49.1851C43.832 48.676 44.2394 48.2832 44.734 48.2832C45.2286 48.2832 45.6359 48.6905 45.6359 49.1851V56.8661C45.6504 57.3607 45.2431 57.768 44.734 57.768Z"
          fill={colors.secondary.main}
        />
        <path
          d="M36.7183 57.768C36.2237 57.768 35.8164 57.3607 35.8164 56.8661V49.1851C35.8164 48.676 36.2237 48.2832 36.7183 48.2832C37.2129 48.2832 37.6203 48.6905 37.6203 49.1851V56.8661C37.6203 57.3607 37.2129 57.768 36.7183 57.768Z"
          fill={colors.secondary.main}
        />
      </g>
      <defs>
        <clipPath id="clip11">
          <rect width="56.9524" height="40.2085" fill="white" transform="translate(24.0469 35.3071)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
