import { QueryResponse, ResponseInterceptor } from 'react-fetching-library';
import { ShowAlert } from '@chhjit/react-components';
import Bugsnag from '@bugsnag/browser';

import { Action, BasicResponseStructure } from 'common/api/types';

type ResponseErrorInterceptor = (showAlert: ShowAlert) => ResponseInterceptor;

export const responseErrorInterceptor: ResponseErrorInterceptor = (showAlert) => () => async (
  action: Action,
  response: QueryResponse<BasicResponseStructure>,
) => {
  if (!action.config?.disableInterceptErrors && response.status !== 200) {
    const errorMsg = response.payload?.meta.errors?.length ? response.payload?.meta.errors[0].message : '';
    const metaSeries = response.payload?.meta.series || '';
    const bugsnagMsg = `${errorMsg} - Meta series: ${metaSeries}`;

    showAlert('Something went wrong, please, refresh the page', { variant: 'error' });
    Bugsnag.notify(new Error(bugsnagMsg));
  }

  return response;
};
