/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Loader } from '@chhjit/react-components';
import { Box, Collapse, FormHelperText, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import {
  CreditCardCVVInput,
  CreditCardExpirationDateInput,
  CreditCardNumberInput,
  CreditCardPostalCodeInput,
  SquarePaymentForm,
} from 'react-square-payment-form';
import clsx from 'clsx';

import { movingRoutes } from 'bMove/routing/AppRoute';
import { ButtonsGroup } from 'common/ui/buttonsGroup/ButtonsGroup';
import { useMovingStateContext } from 'bMove/hooks/useMoving/useMoving';

// eslint-disable-next-line import/order
import { squareInputStyles, useStyles } from './Prepayment.styles';
import 'react-square-payment-form/lib/default.css';

// Override SquarePayment styles
import './SquarePayment.css';

import { PrepaymentProps } from './Prepayment.types';
import { PrepaymentSubmitButton } from './prepaymentSubmitButton/PrepaymentSubmitButton';

const squareApplicationId = process.env.REACT_APP_SQUARE_APP_ID || '';
const isSandbox = squareApplicationId.includes('sandbox');

export const Prepayment = ({
  cardNonceResponseReceived,
  createVerificationDetails,
  handleContinueWithoutPrepayment,
  errors,
  paymentLoading,
  onSubmit,
  hasApiFailed,
}: PrepaymentProps) => {
  const history = useHistory();
  const { prepaymentData } = useMovingStateContext();
  const styles = useStyles();
  const [isSquareLoaded, setIsSquareLoaded] = useState(false);

  const cardNumberError = errors?.find((e) => e.field === 'cardNumber');
  const postalError = errors?.find((e) => e.field === 'postalCode');
  const cvvError = errors?.find((e) => e.field === 'cvv');
  const expDateError = errors?.find((e) => e.field === 'expirationDate');

  if (!prepaymentData?.squareLocationId) {
    return <Redirect to={movingRoutes.step8} />;
  }

  return (
    <div className={styles.wrapper}>
      {!isSquareLoaded && (
        <Box>
          <Loader />
        </Box>
      )}

      <SquarePaymentForm
        sandbox={isSandbox}
        applicationId={squareApplicationId}
        locationId={prepaymentData.squareLocationId}
        key={prepaymentData.squareLocationId}
        formId="chhj-payment"
        apiWrapper="reactjs/0.7.2"
        placeholderPostal="ZIP Code"
        cardNonceResponseReceived={(errors, noonce) => cardNonceResponseReceived(errors, noonce)}
        createVerificationDetails={createVerificationDetails}
        paymentFormLoaded={() => setIsSquareLoaded(true)}
        inputStyles={[squareInputStyles]}
      >
        <Collapse in={isSquareLoaded}>
          <div>
            <div className={styles.container}>
              <Typography variant="h4" className={styles.title}>
                Pre-payment
              </Typography>
              <Box mb={2} />
              <Typography>
                To book the service, you need to make prepayment of{' '}
                <span className={styles.bold}>${prepaymentData.amount}</span>.
                {prepaymentData.prepaymentRefundable && (
                  <>
                    {' '}
                    Refund will be possible until <span className={styles.bold}>{prepaymentData.refundDate}</span>
                  </>
                )}
              </Typography>
              <Box mb={2} />
              <Typography color="secondary" className={styles.label}>
                Credit Card info
              </Typography>
              <fieldset className="sq-fieldsetf">
                <CreditCardNumberInput />
                <FormHelperText className={clsx(styles.helperText, cardNumberError && styles.errorText)}>
                  {cardNumberError?.message || '*required'}
                </FormHelperText>

                <CreditCardExpirationDateInput />
                <FormHelperText className={clsx(styles.helperText, expDateError && styles.errorText)}>
                  {expDateError?.message || '*required'}
                </FormHelperText>

                <CreditCardPostalCodeInput />
                <FormHelperText className={clsx(styles.helperText, postalError && styles.errorText)}>
                  {postalError?.message || '*required'}
                </FormHelperText>

                <CreditCardCVVInput />
                <FormHelperText className={clsx(styles.helperText, cvvError && styles.errorText)}>
                  {cvvError?.message || '*required'}
                </FormHelperText>
              </fieldset>
            </div>

            <Collapse in={hasApiFailed}>
              <Box mt={2}>
                <Typography onClick={handleContinueWithoutPrepayment} className={styles.linkText}>
                  continue without prepayment
                </Typography>
              </Box>
            </Collapse>

            <ButtonsGroup className={styles.buttonsGroup}>
              <Button onClick={() => history.push(movingRoutes.step8)} fullWidth size="large" buttonType="outlined">
                Back
              </Button>
              <PrepaymentSubmitButton
                onSubmit={onSubmit}
                isLoading={paymentLoading}
                label="Pay and book"
              ></PrepaymentSubmitButton>
            </ButtonsGroup>
          </div>
        </Collapse>
      </SquarePaymentForm>
    </div>
  );
};
