import React, { memo } from 'react';
import { Dialog, Box, Typography, FormControl, FormHelperText } from '@material-ui/core';
import { Input, Button } from '@chhjit/react-components';
import { useForm } from 'react-hook-form';

import { ButtonsGroup } from 'common/ui/buttonsGroup/ButtonsGroup';
import { CloseIcon } from 'common/assets/icons/CloseIcon';

import { useStyles } from './CorporateCodeModal.styles';
import { CorporateCodeModalProps } from './CorporateCodeModal.types';

const CorporateCodeModalRaw = ({ open, onClose, onSubmit, isLoading }: CorporateCodeModalProps) => {
  const styles = useStyles();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    defaultValues: { corporateCode: '' },
  });

  return (
    <Dialog open={open} onClose={onClose} className={styles.dialog}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.content}>
        <div className={styles.headerAndCloseWrapper}>
          <Typography variant="h4">Provide your corporate code</Typography>

          <div onClick={onClose} className={styles.closeButton}>
            <CloseIcon />
          </div>
        </div>

        <Box mb={2} />
        <FormControl fullWidth error={!!errors.corporateCode}>
          <Input
            {...register('corporateCode', {
              required: '*required',
              validate: (value: string) => {
                const withoutSpecialChars = value.replace(/_|-/g, '');
                if (withoutSpecialChars.length < 8) {
                  return '*invalid corporate code';
                }
              },
            })}
            // name="corporateCode"
            label="Your corporate code"
            size="medium"
            variant="outlined"
            mask="corporate-code"
            placeholder="__-__-__-__"
            error={!!errors.corporateCode}
            className={styles.input}
          />
          <FormHelperText className={styles.inputError}>{errors.corporateCode?.message}</FormHelperText>
        </FormControl>

        {/* TODO: uncomment this, when description text will be available */}
        {/* <Typography gutterBottom variant="h5">
          Where can I find my corporate code?
        </Typography>

        <Typography>
          Here’s a short description about finding corporate code, but it needs to be updated cause for now I don’t know
          where to find it.
        </Typography>

        <Box mt={3} /> */}

        <ButtonsGroup className={styles.buttonsGroup}>
          <Button disabled={isLoading} onClick={onClose} fullWidth size="large" color="primary" buttonType="text">
            Cancel
          </Button>
          <Button isLoading={isLoading} type="submit" fullWidth size="large" buttonType="twoTone">
            Apply
          </Button>
        </ButtonsGroup>
      </form>
    </Dialog>
  );
};

export const CorporateCodeModal = memo(CorporateCodeModalRaw);
