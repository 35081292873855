import React, { useCallback, useMemo } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Typography, Box } from '@material-ui/core';
import { Button } from '@chhjit/react-components';

import { useLaborStateContext } from 'common/hooks/useLabor/useLabor';
import { ButtonsGroup } from 'common/ui/buttonsGroup/ButtonsGroup';
import { SummaryTabs } from 'common/ui/summaryTabs/SummaryTabs';
import { laborRoutes } from 'common/routing/AppRoute';
import { setReadyToProcessing } from 'common/context/form/formActionCreators/formActionCreators';
import { useFormDispatch } from 'common/hooks/useFormDispatch/useFormDispatch';
import { applyPhoneMask } from 'common/utils/form';
import { ReadyForRequestProcessingEnum } from 'common/context/form/formContext/FormContext.types';

import { useStyles } from './LaborStep3.styles';

export const LaborStep3 = () => {
  const { labor } = useLaborStateContext();
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useFormDispatch();

  const summaryTabsData = useMemo(() => {
    if (!labor?.step1 || !labor?.step2) return [];

    return [
      {
        title: 'Your location',
        onClick: () => history.push(laborRoutes.step1),
        content: [
          { key: 'Address', value: labor.step1.address },
          { key: 'Apt/suite', value: labor.step1.fullAddress.address2 || '-' },
        ],
      },
      {
        title: 'Contact information',
        onClick: () => history.push(laborRoutes.step2),
        content: [
          { key: 'First name', value: labor.step2.firstName },
          { key: 'Last name', value: labor.step2.lastName },
          { key: 'E-mail', value: labor.step2.email },
          { key: 'Contact phone number', value: applyPhoneMask(labor.step2.phone) },
        ],
      },
    ];
  }, [labor, history]);

  const handleNextStep = useCallback(() => {
    dispatch(setReadyToProcessing({ readyForRequestProcessing: ReadyForRequestProcessingEnum.Labor }));
    history.push(laborRoutes.processing);
  }, [dispatch, history]);

  if (!labor?.step1) {
    return <Redirect to={laborRoutes.step1} />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Typography variant="h4" className={styles.title}>
          3. Summary
        </Typography>

        <Typography variant="body1" className={styles.subtitle}>
          Please, review your information below
        </Typography>
      </div>

      <SummaryTabs data={summaryTabsData} />

      <Box mt="auto">
        <ButtonsGroup className={styles.buttonsGroup}>
          <Button onClick={() => history.push(laborRoutes.step1)} fullWidth size="large" buttonType="outlined">
            Back
          </Button>
          <Button onClick={handleNextStep} fullWidth size="large" buttonType="twoTone">
            Contact Me
          </Button>
        </ButtonsGroup>
      </Box>
    </div>
  );
};
