import React, { useCallback, useContext } from 'react';
import { Button } from '@chhjit/react-components';
import { Context as SquarePaymentContext } from 'react-square-payment-form';

import { PrepaymentSubmitButtonProps } from './PrepaymentSubmitButton.types';

// The square useContext call must be used within the <SquarePaymentForm /> component
export const PrepaymentSubmitButton = ({ onSubmit, isLoading, label }: PrepaymentSubmitButtonProps) => {
  const squareContext = useContext(SquarePaymentContext);
  const handleSubmit = useCallback(() => onSubmit(squareContext.onCreateNonce), [onSubmit, squareContext]);

  return (
    <Button
      onClick={handleSubmit}
      disabled={isLoading}
      isLoading={isLoading}
      buttonType="twoTone"
      size="large"
      fullWidth
    >
      {label}
    </Button>
  );
};
