import { SelectOptionType } from '@chhjit/react-components';
import moment from 'moment';

import { movingRoutes } from 'bMove/routing/AppRoute';
import { truckParkingOptions, typeOfDwellingOptions } from 'bMove/app/moving/MovingData';
import { AccessTypeEnum } from 'common/api/types';
import { USADateFormat } from 'common/utils/dateTime';
import { applyPhoneMask } from 'common/utils/form';
import { SingleTab } from 'common/ui/summaryTabs/singleTab/SingleTab.types';
import { MovingState, PrepaymentData } from 'bMove/context/moving/MovingContext.types';
import { formatCurrency, getPriceRange } from 'common/utils/currencyFormatter';

const findLabelForSelectOptions = (options: SelectOptionType<number | string>[], id: number) => {
  return options.find(({ value }) => value === id)?.label || '';
};

type GetMovingSummaryTabsData = {
  moving: MovingState | undefined;
  historyPush: (path: string) => void;
  isCallRequired?: boolean;
  prepaymentData?: PrepaymentData;
};

export const getMovingSummaryTabsData = ({
  moving,
  historyPush,
  isCallRequired,
  prepaymentData,
}: GetMovingSummaryTabsData): SingleTab[] => {
  if (!moving?.step1 || !moving?.step2 || !moving?.step3 || !moving?.step4 || !moving?.step5) {
    return [];
  }

  return [
    {
      title: 'Moving from',
      onClick: () => historyPush(movingRoutes.step1),
      content: [
        { key: 'Address', value: moving.step1.shortAddress },
        { key: 'Apt/suite', value: moving.step1.fullAddress.address2 || '-' },
        { key: 'Dwelling', value: findLabelForSelectOptions(typeOfDwellingOptions, moving.step1.typeOfDwelling) },
        ...(typeof moving.step1.floor === 'number' ? [{ key: 'Floor', value: moving.step1.floor + 1 }] : []),
        { key: 'Access type', value: AccessTypeEnum[moving.step1.accessType] },
        { key: 'Parking truck', value: findLabelForSelectOptions(truckParkingOptions, moving.step1.truckParking) },
        { key: 'Bedrooms', value: moving.step1.rooms },
      ],
    },
    {
      title: 'Moving to',
      onClick: () => historyPush(movingRoutes.step2),
      content: [
        { key: 'Address', value: moving.step2.shortAddress },
        { key: 'Apt/suite', value: moving.step2.fullAddress.address2 || '-' },
        { key: 'Dwelling', value: findLabelForSelectOptions(typeOfDwellingOptions, moving.step2.typeOfDwelling) },
        ...(typeof moving.step2.floor === 'number' ? [{ key: 'Floor', value: moving.step2.floor + 1 }] : []),
        { key: 'Access type', value: AccessTypeEnum[moving.step2.accessType] },
        { key: 'Parking truck', value: findLabelForSelectOptions(truckParkingOptions, moving.step2.truckParking) },
      ],
    },
    {
      title: 'Special items',
      onClick: () => historyPush(movingRoutes.step3),
      content: [
        { key: 'Appliances larger than a dishwasher', value: moving.step3.appliances || '-' },
        { key: 'Items that require more than 2 people to lift', value: moving.step3.heavyItems || '-' },
        { key: 'Items that need to be assembled', value: moving.step3.assemblyItems || '-' },
      ],
      wideKeyField: true,
    },
    {
      title: 'Job date',
      onClick: () => historyPush(movingRoutes.step4),
      content: [
        { key: 'Day', value: moment(moving.step4.date).format(USADateFormat) },
        ...(moving.step6 && isCallRequired === false ? [{ key: 'Time', value: moving.step6.schedule.friendly }] : []),
      ],
    },
    {
      title: 'Contact information',
      onClick: () => historyPush(movingRoutes.step5),
      content: [
        { key: 'First name', value: moving.step5.firstName },
        { key: 'Last name', value: moving.step5.lastName },
        { key: 'E-mail', value: moving.step5.email },
        { key: 'Phone number', value: applyPhoneMask(moving.step5.phone) },
      ],
    },
    ...(moving.step7
      ? [
          {
            title: 'Price',
            onClick: () => historyPush(movingRoutes.step7),
            content: [
              {
                key: 'Job estimate',
                value: getPriceRange(moving.step7.totalPrice.low, moving.step7.totalPrice.high),
              },
              ...(prepaymentData?.isPrepaymentAvailable
                ? [{ key: 'Deposit', value: formatCurrency.format(moving.step7.prepaymentValue) }]
                : []),
            ],
          },
        ]
      : []),
  ];
};
