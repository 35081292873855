import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-fetching-library';
import moment from 'moment';
import { TimePickerSchedule, useAlert } from '@chhjit/react-components';

import { useFormState } from 'common/hooks/useFormState/useFormState';
import { checkAvailabilityAction } from 'common/api/actions/booking/bookingActions';
import { DateFormatForAPI } from 'common/utils/dateTime';
import { isSameSchedule } from 'common/utils/form';
import { CheckAvailabilityResponse } from 'common/api/actions/booking/bookingActions.types';

import { CheckAvailabilityParams, GetTheSameOrSimilarSchedule, Schedules } from './useAvailability.types';

export const useAvailability = () => {
  const history = useHistory();
  const { locationId, zoneId } = useFormState();
  const { showAlert } = useAlert();
  const {
    mutate: checkAvailabilityMutate,
    abort: abortCheckAbailability,
    loading: isCheckingAvailability,
  } = useMutation(checkAvailabilityAction);
  const [schedules, setSchedules] = useState<Schedules>({
    morning: [],
    afternoon: [],
    evening: [],
  });

  const createSchedule = useCallback((availability?: CheckAvailabilityResponse['availability']) => {
    const createdSchedule = {
      morning: [],
      afternoon: [],
      evening: [],
    } as Schedules;

    availability?.forEach((item) => {
      const timePeriod = { ...item.schedule, resourceId: item.resource.id } as TimePickerSchedule;
      createdSchedule[item.schedule.period].push(timePeriod);
    });

    setSchedules(createdSchedule);
    return createdSchedule;
  }, []);

  const getAvailabilitySchedule = useCallback(
    async ({ postal, jobDate, jobDuration, categoryId }: CheckAvailabilityParams) => {
      if (!locationId || zoneId === undefined) {
        return null;
      }

      abortCheckAbailability();
      const { payload } = await checkAvailabilityMutate({
        locationId,
        postal,
        category_id: categoryId,
        view_date: moment(jobDate).format(DateFormatForAPI),
        duration: jobDuration,
        zone_id: zoneId,
      });

      return createSchedule(payload?.availability);
    },
    [checkAvailabilityMutate, abortCheckAbailability, createSchedule, locationId, zoneId],
  );

  const getTheSameOrSimilarSchedule = useCallback(
    async ({ availabilityParams, currentSchedule, redirectionRoute }: GetTheSameOrSimilarSchedule) => {
      const schedules = await getAvailabilitySchedule(availabilityParams);
      if (!schedules) return;

      const foundSameSchedule = schedules[currentSchedule.period].find((schedule) =>
        isSameSchedule(schedule, currentSchedule),
      );

      if (!foundSameSchedule && redirectionRoute) {
        showAlert('The selected time is not available, please select another time.', {
          variant: 'error',
        });

        history.push(redirectionRoute);
        return;
      }
      return foundSameSchedule;
    },
    [getAvailabilitySchedule, history, showAlert],
  );

  return {
    getAvailabilitySchedule,
    schedules,
    isCheckingAvailability,
    getTheSameOrSimilarSchedule,
  };
};
