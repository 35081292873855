import React, { useCallback } from 'react';
import { useAlert } from '@chhjit/react-components';
import { useMutation } from 'react-fetching-library';

import { mapPartnerResponseToStateData } from 'common/context/version/VersionContextController.utils';
import { getPartnerInfoAction } from 'common/api/actions/partner/partnerActions';
import { setPartnerData } from 'common/context/form/formActionCreators/formActionCreators';
import { useFormDispatch } from 'common/hooks/useFormDispatch/useFormDispatch';

import { CorporateCodeModalContainerProps } from './CorporateCodeModal.types';
import { CorporateCodeModal } from './CorporateCodeModal';

export const CorporateCodeModalContainer = ({ open, onClose }: CorporateCodeModalContainerProps) => {
  const formDispatch = useFormDispatch();

  const { mutate: getPartnerInfoMutate, abort: abortGetPartnerInfo, loading: isGettingPartnerInfo } = useMutation(
    getPartnerInfoAction,
  );

  const { showAlert } = useAlert();

  const getPartnerInfo = useCallback(
    async (partnerToken: string) => {
      abortGetPartnerInfo();

      const subpartnerToken = partnerToken.replaceAll('-', '').toUpperCase();

      const { payload } = await getPartnerInfoMutate({
        subpartner_token: subpartnerToken,
      });

      const isError = !payload?.subpartners?.length || payload.meta.errors?.length;

      if (isError) {
        showAlert(`The corporate code "${partnerToken.toUpperCase()}" does not exist`, { variant: 'error' });

        return;
      }

      formDispatch(setPartnerData(mapPartnerResponseToStateData(payload.subpartners[0], partnerToken)));
    },
    [getPartnerInfoMutate, abortGetPartnerInfo, formDispatch, showAlert],
  );

  const handleSubmit = useCallback(
    async ({ corporateCode }: { corporateCode: string }) => {
      await getPartnerInfo(corporateCode);

      onClose();
    },
    [getPartnerInfo, onClose],
  );

  return <CorporateCodeModal open={open} isLoading={isGettingPartnerInfo} onSubmit={handleSubmit} onClose={onClose} />;
};
