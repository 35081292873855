import React, { useState, useCallback, useRef, useMemo } from 'react';
import { Paper, MenuItem, Popper, Fade, ClickAwayListener } from '@material-ui/core';
import { Input } from '@chhjit/react-components';
import moment from 'moment';
import clsx from 'clsx';

import { DropdownIcon } from 'common/assets/icons/DropdownIcon';
import { USADateFormat } from 'common/utils/dateTime';
import { Calendar } from 'common/ui/calendar/Calendar';

import { dropdownData } from './dropdownData';
import { SelectDateProps } from './SelectDate.types';
import { useStyles } from './SelectDate.styles';

const timeMap: Record<number, string> = {
  0: 'today',
  1: 'tomorrow',
  2: 'in 2 days',
};

export const SelectDate = ({ name, value, onSelect }: SelectDateProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const styles = useStyles({ dropdownWidth: anchorRef.current?.offsetWidth });

  const formattedDate = useMemo(() => {
    const momentDate = moment(value);
    const formattedDay = momentDate.format(USADateFormat);

    for (const day of [0, 1, 2]) {
      //create day: today/tomorrow/in 2 days
      const nextDayFromToday = moment().add(day, 'day');
      // then check if date chosen from calendar is equal to one of three options
      if (momentDate.isSame(nextDayFromToday, 'day')) {
        return `${timeMap[day]} (${formattedDay})`;
      }
    }
    return formattedDay;
  }, [value]);

  const handleClose = useCallback((event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    } else {
      setOpen(false);
    }
  }, []);

  const handleSelect = useCallback(
    (date: Date) => {
      onSelect(date);
      setOpen(false);
    },
    [onSelect],
  );

  const filteredDropdownData = useMemo(() => dropdownData.filter((item) => !formattedDate.includes(item.key)), [
    formattedDate,
  ]);

  return (
    <>
      <div
        ref={anchorRef}
        onClick={() => setOpen((prevState) => !prevState)}
        className={styles.wrapper}
        data-testid="select-date"
      >
        <Input
          name={name}
          endAdornment={<DropdownIcon className={clsx([open && styles.rotateAdornment, styles.inputEndAdornment])} />}
          label="Select job date"
          size="medium"
          variant="outlined"
          value={formattedDate}
          inputProps={{
            readOnly: true,
          }}
          className={styles.readOnly}
        />
      </div>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        ref={listRef}
        placement="bottom"
        transition
        className={styles.dropdown}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <div ref={listRef}>
              <ClickAwayListener onClickAway={handleClose}>
                <Paper elevation={1}>
                  {filteredDropdownData.map(({ key, data }) => (
                    <MenuItem
                      key={key}
                      onClick={() => {
                        if (key === 'calendar') {
                          setOpen(false);
                          setCalendarOpen(true);
                        } else if (data.date) {
                          handleSelect(data.date);
                        }
                      }}
                      className={styles.dropdownItem}
                    >
                      {data.text}
                    </MenuItem>
                  ))}
                </Paper>
              </ClickAwayListener>
            </div>
          </Fade>
        )}
      </Popper>

      <Calendar
        open={calendarOpen}
        onClose={() => setCalendarOpen(false)}
        onDateChange={handleSelect}
        date={value}
        minDate={moment().toDate()}
      />
    </>
  );
};
