import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: 1244,
    margin: '0 auto',
    paddingBottom: 72,
    backgroundColor: colors.basic.white,

    [theme.breakpoints.up('sm')]: {
      paddingTop: 56,
      paddingBottom: 80,
    },

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  imgContainer: {
    width: '100%',
    position: 'relative',
    height: 295,

    [theme.breakpoints.up('sm')]: {
      width: 'calc(100vw - 80px - 16px)', // -80px of margins and 16 is additional space
      maxWidth: 616,
      height: 592,
      marginLeft: 50,

      marginRight: 30, // cover translate() in ::after

      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        transform: 'translate(30px, 30px)',
        backgroundColor: 'transparent',
        border: `3px solid ${colors.primary.main}`,

        [theme.breakpoints.up('md')]: {
          transform: 'translate(64px, 48px)',
        },
      },
    },

    [theme.breakpoints.up('md')]: {
      maxWidth: 'auto',
      width: 608,
      height: 496,
      marginRight: 64, // cover translate() in ::after
      marginLeft: 40, // cover half of width "imgDecorationBig"
    },
  },
  imgWrapper: {
    height: '100%',
    width: '100%',
    opacity: 0.7,

    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  imgDecorationSmall: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    transform: 'translateY(50%)',
  },
  imgDecorationBig: {
    display: 'block',
    position: 'absolute',
    left: 0,
    top: 0,
    transform: 'translateX(-50%)',
  },
  title: {
    color: colors.grey190,
    padding: '0 24px',
    margin: '96px 0 48px',

    [theme.breakpoints.up('sm')]: {
      ...theme.typography.h2,
      maxWidth: 480,
      margin: '64px 0 32px 56px',
      padding: 0,
    },

    [theme.breakpoints.up('md')]: {
      marginTop: 80,
      maxWidth: 376,
    },
  },
  importantTitlePart: {
    color: colors.primary.main,
  },
  button: {
    margin: '0 16px',
    minWidth: 150,

    [theme.breakpoints.up('sm')]: {
      margin: 0,
      marginLeft: 48,

      '& button': {
        maxWidth: 248,
      },
    },

    [theme.breakpoints.up('md')]: {
      marginLeft: 56,
    },
  },
}));
