import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '8px',
    display: 'flex',
    alignItems: 'center',

    '&:nth-child(even)': {
      backgroundColor: colors.basic.white,
    },
  },

  title: {
    marginLeft: 8,
    color: colors.grey160,
    fontWeight: 'bold',
    fontSize: 14,

    maxWidth: 120,
  },
  img: {
    width: 60,

    [theme.breakpoints.down('xs')]: {
      marginLeft: 8,
    },
  },
  imgAndTitleWrapper: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  price: {
    marginLeft: 16,
  },
  crossedText: {
    fontWeight: 'normal',
    textDecoration: 'line-through',
    color: colors.grey80,
  },
  stickToRight: {
    marginLeft: 'auto',
  },
}));
