import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { rudderanalytics } from 'common/utils/rudderanalytics';
import { AppRoutes } from 'common/routing/AppRoutes';
import { Navigation } from 'common/ui/navigation/Navigation';
import { useVersionContext } from 'common/hooks/useVersion/useVersion';

import { useStyles } from './App.styles';

export const App = () => {
  const { pathname } = useLocation();

  const styles = useStyles();

  const { moveVer } = useVersionContext();

  if (!process.env.REACT_APP_API_URL) {
    throw new Error('REACT_APP_API_URL is not defined!');
  }
  if (!process.env.REACT_APP_DOMAIN_URL) {
    throw new Error('REACT_APP_DOMAIN_URL is not defined!');
  }
  if (!process.env.REACT_APP_BUGSNAG_API_KEY) {
    throw new Error('REACT_APP_BUGSNAG_API_KEY is not defined!');
  }
  if (!process.env.REACT_APP_GOOGLE_API_KEY) {
    throw new Error('REACT_APP_GOOGLE_API_KEY is not defined!');
  }
  if (!process.env.REACT_APP_GTM_ID) {
    throw new Error('REACT_APP_GTM_ID is not defined!');
  }
  if (!process.env.REACT_APP_GA_TRACKING_ID) {
    throw new Error('REACT_APP_GA_TRACKING_ID is not defined!');
  }
  if (moveVer === 'B' && !process.env.REACT_APP_SQUARE_APP_ID) {
    throw new Error('REACT_APP_SQUARE_APP_ID is not defined!');
  }

  useEffect(() => {
    if (process.env.REACT_APP_IS_SANDBOX !== 'true') {
      // page tracking for google analitycs
      const pagePath = window.location.origin + pathname;

      gtag('config', process.env.REACT_APP_GA_TRACKING_ID, { page_path: pagePath });
    }
    rudderanalytics.page();
  }, [pathname]);

  return (
    <div className={styles.app} id="app">
      <Navigation />

      <div className={styles.content}>
        <AppRoutes />
      </div>
    </div>
  );
};
