import React from 'react';
import { Button } from '@chhjit/react-components';
import { Grid, Typography } from '@material-ui/core';

import { useStyles } from './ErrorBoundaryFallback.styles';
import { ErrorBoundaryFallbackProps } from './ErrorBoundaryFallback.types';

export const ErrorBoundaryFallback = ({ clearError }: ErrorBoundaryFallbackProps) => {
  const styles = useStyles();
  return (
    <Grid className={styles.container} spacing={3} container alignContent="center" justify="center" direction="column">
      <Grid item>
        <Typography variant="h1" color="secondary">
          Error
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          <strong>Opps! Something went wrong!</strong>
        </Typography>
        <Typography variant="body1">We are working on the problem. Please try to reload or try again later!</Typography>
      </Grid>
      <Grid item>
        <Button buttonType="outlined" onClick={clearError}>
          Reload
        </Button>
      </Grid>
    </Grid>
  );
};
