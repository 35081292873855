import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

export const useStyles = makeStyles((theme) => ({
  form: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 1,
    maxWidth: 489,
  },
  inputError: {
    margin: '4px 0 6px',
    lineHeight: '14px',
    fontSize: 14,
    height: 14,
  },
  label: {
    marginBottom: 8,
    opacity: 0.8,
  },
  tile: {
    [theme.breakpoints.up('md')]: {
      marginRight: 16,
    },
  },
  labelError: {
    color: colors.functionals.alert,
    opacity: 1,
  },
  tipbox: {
    maxWidth: 432,
  },
}));
