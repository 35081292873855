import { useCallback, useMemo } from 'react';
import { useMutation } from 'react-fetching-library';

import { mapAccountToSegmentTraits, rudderanalytics } from 'common/utils/rudderanalytics';
import { useFormDispatch } from 'common/hooks/useFormDispatch/useFormDispatch';
import { useFormState } from 'common/hooks/useFormState/useFormState';
import { setGlobalData, setAccountData } from 'common/context/form/formActionCreators/formActionCreators';
import { createNewAccountAction, pingAction, updateAccountAction } from 'common/api/actions/account/accountActions';
import { AccountResponse } from 'common/api/actions/account/accountActions.types';
import { useFeatureFlags } from '../use-feature-flags/use-feature-flags';

import { MapStateToAccountParams, mapStateToAccountPayload } from './useAccount.utils';

export const useAccount = () => {
  const { locationId, accountId, token, partnerData } = useFormState();

  const dispatch = useFormDispatch();

  const { mutate: createAccountMutate, loading: isCreatingAccount } = useMutation(createNewAccountAction);
  const { mutate: updateAccountMutate, loading: isUpdatingAccount } = useMutation(updateAccountAction);
  const { mutate: sendPingMutate, loading: isSendingPing } = useMutation(pingAction);

  const { toggles } = useFeatureFlags();

  const isRecreatingAccount = useMemo(() => isCreatingAccount || isSendingPing, [isCreatingAccount, isSendingPing]);

  const isCreatingOrUpdatingAccount = useMemo(() => isCreatingAccount || isUpdatingAccount, [
    isCreatingAccount,
    isUpdatingAccount,
  ]);

  const feature_flags = useMemo(() => {
    const flags = [];

    if (toggles.OnlineBooking_TrackVersion) {
      flags.push({
        key: 'OnlineBooking_TrackVersion',
        value: 'v2',
      });
    }

    return flags;
  }, [toggles.OnlineBooking_TrackVersion]);

  const createOrUpdateAccount = useCallback(
    async (
      mapStateToAccountParams: MapStateToAccountParams,
      nearestLocationId = locationId,
      partnerInfo = partnerData,
      forceCreate?: boolean,
    ) => {
      if (!nearestLocationId) {
        return;
      }

      const accountPayload = mapStateToAccountPayload({
        ...mapStateToAccountParams,
        partnerData: partnerInfo && {
          ...partnerInfo,
          source_id: toggles.PartnerManagement_DefaultReferralSource ? partnerInfo?.source_id : undefined,
        },
      });

      let account: AccountResponse | undefined;

      if (!forceCreate && accountId && token) {
        const { payload } = await updateAccountMutate({
          accountId,
          token,
          locationId: nearestLocationId,
          payload: accountPayload,
        });

        if (!payload?.accounts?.length) {
          return;
        }

        account = payload?.accounts?.[0];
      } else {
        const { payload } = await createAccountMutate({
          locationId: nearestLocationId,
          payload: { ...accountPayload, feature_flags },
        });

        if (!payload?.tokens?.length) {
          return;
        }

        account = payload?.tokens?.[0].account;

        dispatch(
          setGlobalData({
            token: payload.tokens[0].token,
            accountId: account.id,
            isReadyToPing: true,
          }),
        );
      }

      if (!account) {
        return;
      }

      dispatch(
        setAccountData({
          id: account.id,
          firstName: account.first_name,
          lastName: account.last_name,
          email: account.email,
          phone: account.phone,
          locationId: account.location.id,
          postal: account.billing_address.postal,
        }),
      );

      const segmentTraits = mapAccountToSegmentTraits(account);
      rudderanalytics.identify(`account-${account.id}`, segmentTraits);

      const segmentFormEventTraits = {
        firstName: account.first_name,
        lastName: account.last_name,
        email: account.email,
        phone: account.phone,
      };
      rudderanalytics.track(`Contact Form Fill`, segmentFormEventTraits);

      return account;
    },
    [
      locationId,
      accountId,
      token,
      partnerData,
      feature_flags,
      toggles.PartnerManagement_DefaultReferralSource,
      createAccountMutate,
      updateAccountMutate,
      dispatch,
    ],
  );

  const recreateAccountWithNewLocationId = useCallback(
    async (mapStateToAccountParams: MapStateToAccountParams, newLocationId: number) => {
      if (!token || !accountId || !locationId) return;

      const accountPayload = mapStateToAccountPayload({
        ...mapStateToAccountParams,
        partnerData: partnerData && {
          ...partnerData,
          source_id: toggles.PartnerManagement_DefaultReferralSource ? partnerData?.source_id : undefined,
        },
      });

      const { payload: pingPayload } = await sendPingMutate({
        locationId,
        accountId,
        token,
        booking_complete: true,
      });

      if (pingPayload?.meta.status.code !== 200) return;

      dispatch(setGlobalData({ isReadyToPing: false }));

      const { payload } = await createAccountMutate({
        locationId: newLocationId,
        payload: { ...accountPayload, feature_flags },
      });

      if (!payload?.tokens?.length) return;

      const account = payload.tokens[0].account;

      dispatch(
        setGlobalData({
          token: payload.tokens[0].token,
          accountId: account.id,
          locationId: newLocationId,
          isReadyToPing: true,
          account: {
            id: account.id,
            firstName: account.first_name,
            lastName: account.last_name,
            email: account.email,
            phone: account.phone,
            locationId: account.location.id,
            postal: account.billing_address.postal,
          },
        }),
      );

      return account;
    },
    [
      token,
      accountId,
      locationId,
      partnerData,
      feature_flags,
      toggles.PartnerManagement_DefaultReferralSource,
      dispatch,
      sendPingMutate,
      createAccountMutate,
    ],
  );

  return {
    isCreatingOrUpdatingAccount,
    isRecreatingAccount,
    createOrUpdateAccount,
    recreateAccountWithNewLocationId,
  };
};
