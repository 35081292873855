import { isWebUri } from 'valid-url';

import oneEightTruckloadImg from 'common/assets/images/oneEightTruckload.png';
import halfTruckloadImg from 'common/assets/images/halfTruckload.png';
import fullTruckloadImg from 'common/assets/images/fullTruckload.png';
import { JunkRemovalPricing } from 'common/context/junkRemoval/JunkRemovalContext.types';
import { DiscountTypeEnum, GetPartnerInfoAction } from 'common/api/actions/partner/partnerActions.types';
import { CategoryIdEnum } from 'common/api/types';

import { JunkRemovalDiscount } from './junkRemovalStep5ItemForPartners/JunkRemovalStep5ItemForPartners.types';

type PricingData = {
  title: keyof JunkRemovalPricing;
  imgSrc?: string;
};

export const pricingsData: PricingData[] = [
  {
    title: 'Less than 1/8th truckload',
    imgSrc: oneEightTruckloadImg,
  },
  {
    title: '1/2 truckload',
    imgSrc: halfTruckloadImg,
  },
  {
    title: 'Full truckload',
    imgSrc: fullTruckloadImg,
  },
];

export const pricingsDataForPartners: PricingData[] = [
  {
    title: 'Less than 1/8th truckload',
    imgSrc: oneEightTruckloadImg,
  },
  { title: '1/6 truckload' },
  { title: '1/4 truckload' },
  { title: '1/3 truckload' },
  { title: '3/8 truckload' },
  {
    title: '1/2 truckload',
    imgSrc: halfTruckloadImg,
  },
  { title: '5/8 truckload' },
  { title: '2/3 truckload' },
  { title: '5/6 truckload' },
  { title: '7/8 truckload' },
  {
    title: 'Full truckload',
    imgSrc: fullTruckloadImg,
  },
];

export const applyDiscountToPrice = (oldPrice: number, discount: GetPartnerInfoAction.Discount) => {
  if (discount.type.id === DiscountTypeEnum.Percent) {
    return Number((oldPrice * (1 - discount.amount / 100)).toFixed(0));
  }

  if (discount.type.id === DiscountTypeEnum.DollarAmount) {
    return Number((oldPrice - discount.amount).toFixed(0));
  }
};

export const getDiscountData = (discounts: GetPartnerInfoAction.Discount[]): JunkRemovalDiscount | undefined => {
  const filteredDiscounts = discounts.filter((discount) => discount.category.id === CategoryIdEnum.junk); // there should be one
  const discount = filteredDiscounts.length ? filteredDiscounts[0] : undefined;

  if (!discount) {
    return;
  }

  let discountDetails: JunkRemovalDiscount['discountDetails'];

  if (discount?.type.id === DiscountTypeEnum.PricingOverride && discount?.details) {
    if (isWebUri(discount.details)) {
      discountDetails = {
        type: 'url',
        details: discount.details,
      };
    } else {
      discountDetails = {
        type: 'html',
        details: discount.details,
      };
    }
  }

  return {
    discount,
    discountDetails,
  };
};
