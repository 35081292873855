import React, { useMemo } from 'react';
import { Hidden, Typography } from '@material-ui/core';
import { Button } from '@chhjit/react-components';

import { WeAreNotHereYetIcon } from 'common/assets/icons/WeAreNotHereYetIcon';
import { useFormState } from 'common/hooks/useFormState/useFormState';

import { useStyles } from './Unserviced.styles';
import { UnservicedProps } from './Unserviced.types';

export const Unserviced = ({ type }: UnservicedProps) => {
  const styles = useStyles();
  const { partnerData } = useFormState();

  const name = useMemo(() => {
    return type === 'junkHaulers' ? 'Junk Haulers' : 'Movers';
  }, [type]);

  const btnLink = useMemo(() => {
    return type === 'junkHaulers' ? 'http://www.homeadvisor.com/ext/32014119' : 'http://t.chhj.com/VendorMoveQuote';
  }, [type]);

  const isNationalAccount = useMemo(() => partnerData?.type?.id === 1, [partnerData?.type?.id]);

  const content = useMemo(
    () =>
      !isNationalAccount
        ? {
            title: 'Sorry, We are not here yet',
            subtitle: 'We don’t service your neighborhood yet',
            text: `Click the links below to find qualified ${name} in your area, or to learn more about our other services.`,
          }
        : {
            title: 'Please contact us',
            subtitle: 'This request cannot be completed online',
            text: (
              <>
                Please email <a href="mailto:nabooking@chhj.com">nabooking@chhj.com</a> or call{' '}
                <a href="tel:+1-813-853-4623">813-853-4623</a> so that we may assist you with your service.
              </>
            ),
          },
    [isNationalAccount, name],
  );

  return (
    <div className={styles.bgWrapper}>
      <div className={styles.wrapper}>
        <Hidden smUp>
          <Typography variant="h2" className={styles.title}>
            {content.title}
          </Typography>
        </Hidden>

        <div className={styles.flexWrapper}>
          <WeAreNotHereYetIcon className={styles.icon} />
          <div>
            <Hidden xsDown>
              <Typography variant="h2" className={styles.title}>
                {content.title}
              </Typography>
            </Hidden>

            <Typography className={styles.bolderText}>{content.subtitle}</Typography>
            <Typography className={styles.text}>{content.text}</Typography>
          </div>
        </div>

        {!isNationalAccount && (
          <div className={styles.buttonsWrapper}>
            <Button href="https://www.collegehunkshaulingjunk.com" fullWidth size="large" buttonType="twoTone">
              about college h.u.n.k.s.
            </Button>

            <Button href={btnLink} fullWidth size="large" buttonType="outlined">
              {name} in my area
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
