import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

export const useStyles = makeStyles((theme) => ({
  app: {
    height: '100%',
    width: '100%',
    minHeight: '100vh',

    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('xs')]: {
      minHeight: '-webkit-fill-available',
    },
  },
  content: {
    flexGrow: 1,
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      backgroundColor: colors.grey10,
    },

    display: 'flex',
    flexDirection: 'column',
  },
}));
