import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

const truckHeight = '128px';
const truckRoadLength = '472px';

export const useStyles = makeStyles((theme) => ({
  '@keyframes drive': {
    from: {
      transform: 'translateX(-100%)',
    },
    to: {
      transform: `translateX(${truckRoadLength})`,
    },
  },
  bgWrapper: {
    backgroundColor: colors.basic.white,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  wrapper: {
    flexGrow: 1,
    padding: '33px 24px',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',

    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
  },
  truckContainer: {
    width: '100%',
    maxWidth: truckRoadLength,
    margin: '0 auto',
    overflow: 'hidden',

    height: truckHeight,
    borderBottom: `2px solid ${colors.grey30}`,
  },
  truck: {
    animation: `$drive 2s infinite ease-out`,
    fontSize: truckHeight,
  },
  text: {
    marginTop: '18vh',

    [theme.breakpoints.up('sm')]: {
      margin: '17vh 0',
    },

    [theme.breakpoints.up('md')]: {
      margin: '55px 0 0',
    },
  },
}));
