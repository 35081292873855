import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

export const useStyles = makeStyles((theme) => ({
  dropdown: {
    zIndex: theme.zIndex.modal,
    width: ({ dropdownWidth }: { dropdownWidth?: number }) => dropdownWidth,
  },
  dropdownItem: {
    paddingRight: 15,
    whiteSpace: 'normal',
    color: colors.grey120,
    fontSize: 14,
    lineHeight: '24px',
    padding: '8px 16px',
    letterSpacing: '0.15px',
    overflow: 'hidden',

    '&:nth-child(odd)': {
      backgroundColor: colors.grey10,
    },
    '&:hover': {
      backgroundColor: colors.grey20,
    },
  },
  activeDropdownItem: {
    '&&': {
      backgroundColor: colors.grey20,
    },
  },
  mainText: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  searchQueryMatch: {
    color: colors.grey190,
  },
  input: {
    '& .MuiInputBase-input.MuiInputBase-inputAdornedEnd': {
      paddingRight: 50,
    },
  },
  checkIcon: {
    fontSize: '24px',
    position: 'absolute',
    right: 19,
    top: 0,
    color: colors.functionals.success,
  },
}));
