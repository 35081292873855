import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

export const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    padding: '16px 16.5px 0',

    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },

    '& .MuiBreadcrumbs-separator': {
      margin: '0 3px',
    },

    '& .MuiTypography-root': {
      fontSize: 14,
      lineHeight: '17px',
      textTransform: 'lowercase',
    },
    '& p:not(:first-child)': {
      color: colors.grey190,
    },
  },
  home: {
    textDecoration: 'underline',
    color: colors.secondary.main,
    display: 'block',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
