import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

export const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    maxWidth: 489,
  },
  calendar: {
    [theme.breakpoints.up('sm')]: {
      margin: '16px 0 53px',
      backgroundColor: colors.basic.white,
      boxShadow: '0 0 16px rgba(134, 145, 166, 0.16)',
    },

    [theme.breakpoints.up('md')]: {
      marginTop: 48,
      marginBottom: 25,
    },
  },
}));
