import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

export const HappyClientsIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="70" height="70" viewBox="0 0 70 70" fill="none" style={{ fill: 'none' }}>
      <path
        d="M34.9877 61.3023C49.4875 61.3023 61.242 49.5478 61.242 35.048C61.242 20.5481 49.4875 8.7937 34.9877 8.7937C20.4878 8.7937 8.7334 20.5481 8.7334 35.048C8.7334 49.5478 20.4878 61.3023 34.9877 61.3023Z"
        fill={colors.basic.white}
      />
      <path
        d="M36.746 63.9395C51.2458 63.9395 63.0003 52.185 63.0003 37.6852C63.0003 23.1854 51.2458 11.4309 36.746 11.4309C22.2461 11.4309 10.4917 23.1854 10.4917 37.6852C10.4917 52.185 22.2461 63.9395 36.746 63.9395Z"
        fill={colors.primary.light}
      />
      <path
        d="M49.9898 38.7986C49.9898 42.7775 48.4091 46.5934 45.5956 49.4069C42.7821 52.2204 38.9662 53.801 34.9873 53.801C31.0084 53.801 27.1925 52.2204 24.379 49.4069C21.5655 46.5934 19.9849 42.7775 19.9849 38.7986H49.9898Z"
        stroke={colors.secondary.main}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
      <path
        d="M27.4861 31.2972C27.4861 30.3025 27.0909 29.3485 26.3876 28.6452C25.6842 27.9418 24.7302 27.5466 23.7355 27.5466C22.7408 27.5466 21.7868 27.9418 21.0834 28.6452C20.38 29.3485 19.9849 30.3025 19.9849 31.2972"
        stroke={colors.secondary.main}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M49.99 31.2972C49.99 30.3025 49.5948 29.3485 48.8915 28.6452C48.1881 27.9418 47.2341 27.5466 46.2394 27.5466C45.2447 27.5466 44.2907 27.9418 43.5873 28.6452C42.8839 29.3485 42.4888 30.3025 42.4888 31.2972"
        stroke={colors.secondary.main}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M34.9877 61.3023C49.4875 61.3023 61.242 49.5478 61.242 35.048C61.242 20.5481 49.4875 8.7937 34.9877 8.7937C20.4878 8.7937 8.7334 20.5481 8.7334 35.048C8.7334 49.5478 20.4878 61.3023 34.9877 61.3023Z"
        stroke={colors.primary.main}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </SvgIcon>
  );
};
