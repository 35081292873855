import { GetPartnerInfoAction } from 'common/api/actions/partner/partnerActions.types';
import { FormStatePartnerData } from '../form/formReducer/formReducer.types';

export const mapPartnerResponseToStateData = (
  response: GetPartnerInfoAction.Partner,
  partnerToken: string,
): FormStatePartnerData => {
  return {
    token: partnerToken,
    subpartnerId: response.id,
    subpartnerName: response.name,
    partnerName: response.partner.name,
    name: response.name,
    logoUrl: response.logo.url,
    discounts: response.discounts,
    heroTitle: response.booking.title,
    heroImg: response.booking.image_url,
    shouldDisplayPartnersFields: response.type.id !== 2,
    disabledCategories: response.online_booking_disabled_categories,
    type: response.type,
    source_id: Number(response.source_id),
    /** BUSINESS LOGIC WARNING
     * If the type->id for the subpartner response is 2 (strategic alliance),
     * then hide partnersFields (with cordporateCode checkbox) on the
     * contact information page.
     */
  };
};
