import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

export const LaborIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="56" height="57" viewBox="0 0 56 57" fill="none">
      <path
        d="M27.3583 42.9786C27.2417 42.8853 27.125 42.8036 26.9967 42.7219C27.055 42.8269 27.1017 42.9319 27.1483 43.0369L27.3583 42.9786Z"
        fill={colors.primary.light}
      />
      <path
        d="M23.065 51.5652C22.6217 51.6586 22.1783 51.7052 21.735 51.7052C21.595 51.7052 21.4667 51.6819 21.3267 51.6702C22.54 52.4402 24.0333 52.7552 25.4917 52.4402C28.2917 51.6586 29.9717 48.7302 29.3533 45.9419C29.2017 45.3469 28.9333 44.8219 28.6183 44.3319L27.6033 44.6002C28.0233 47.8086 26.0633 50.9002 23.065 51.5652Z"
        fill={colors.primary.light}
      />
      <path
        d="M24.2083 41.9169C23.7533 41.9169 23.2983 41.9519 22.8317 42.0803C20.0317 42.8619 18.3517 45.7903 18.97 48.5786C19.0517 48.8703 19.1683 49.1503 19.2967 49.4186C20.3 50.0019 21.5017 50.2236 22.6917 49.9669C24.955 49.4653 26.4717 46.8869 25.9233 44.4369C25.6783 43.4103 25.0483 42.5353 24.2083 41.9169Z"
        fill={colors.primary.light}
      />
      <path
        d="M37.5083 18.3969L39.0133 18.0003L37.1 11.0003C36.89 10.2303 36.2717 9.69361 35.5483 9.53027L37.52 16.8569C37.6717 17.3703 37.66 17.8953 37.5083 18.3969Z"
        fill={colors.primary.light}
      />
      <path
        d="M20.475 15.5152L22.2483 22.2352C22.2483 22.2352 22.2483 22.2352 22.26 22.2352L35.1167 18.7702C35.4317 18.6886 35.6883 18.4902 35.8517 18.2102C36.015 17.9302 36.05 17.6036 35.9683 17.2886L33.9267 9.72858L21.9333 12.9952C20.8367 13.2986 20.1833 14.4186 20.475 15.5152Z"
        fill={colors.primary.light}
      />
      <path
        d="M39.5383 19.5403L23.6717 23.7986C23.3217 23.8919 23.03 24.067 22.785 24.3003L23.065 25.3736L26.7283 38.6736C27.0317 38.732 27.3467 38.7203 27.65 38.6386L45.71 33.797C46.2583 33.6453 46.725 33.2953 47.005 32.8053C47.285 32.3153 47.3667 31.7319 47.215 31.1836L44.345 20.4619C44.275 20.2169 44.17 19.9953 44.03 19.797L41.8483 20.427C40.95 20.6953 40.0167 20.2986 39.5383 19.5403ZM43.7967 29.037L42.2217 29.457L40.8217 24.1953L42.3967 23.7753L43.7967 29.037Z"
        fill={colors.primary.light}
      />
      <path
        d="M48.8017 30.7636C49.0583 31.7319 48.93 32.7469 48.4283 33.6219C47.9267 34.4969 47.11 35.1153 46.1417 35.3719L28.0817 40.2136C27.8133 40.2836 27.545 40.3186 27.2767 40.3303C27.7433 41.1119 28.6767 41.5319 29.5867 41.2753L49.2917 35.9553C50.3883 35.6636 51.0417 34.5319 50.75 33.4236L47.46 21.0103C47.2383 20.1703 46.5383 19.5986 45.7217 19.4936C45.8033 19.6686 45.8733 19.8436 45.9317 20.0419L48.8017 30.7636Z"
        fill={colors.primary.light}
      />
      <path
        d="M50.8667 37.5069C50.75 37.0753 50.3067 36.8069 49.8633 36.9353L27.3583 42.9903L27.1483 43.0486C27.1017 42.9436 27.055 42.8386 26.9967 42.7336C26.4483 41.6836 25.6317 40.8086 24.605 40.2136C23.415 39.5253 22.05 39.3036 20.72 39.5136L12.285 7.96693C12.2383 7.75693 12.0983 7.57026 11.9 7.46526L5.88001 4.10526C5.48334 3.88359 4.99334 4.02359 4.77167 4.42026C4.55001 4.81693 4.69001 5.30693 5.08667 5.52859L10.8033 8.71359L19.1567 39.9569C16.52 41.1586 14.945 44.1686 15.5983 47.1319C16.3217 49.7219 18.7017 51.5069 21.315 51.6819C21.455 51.6936 21.5833 51.7169 21.7233 51.7169C22.1667 51.7169 22.61 51.6703 23.0533 51.5769C26.0517 50.9119 28.0117 47.8086 27.5917 44.6119L28.6067 44.3436L50.2833 38.5103C50.7267 38.3819 50.9833 37.9386 50.8667 37.5069ZM22.7033 49.9786C21.5133 50.2353 20.3 50.0136 19.3083 49.4303C18.2933 48.8469 17.5 47.9019 17.1733 46.7469C16.6367 44.3436 18.1183 41.8586 20.4517 41.2053C20.8367 41.1003 21.2333 41.0419 21.6183 41.0419C22.365 41.0419 23.1117 41.2403 23.7883 41.6253C23.94 41.7069 24.0683 41.8236 24.2083 41.9286C25.0483 42.5469 25.6667 43.4219 25.935 44.4603C26.4833 46.8869 24.9783 49.4769 22.7033 49.9786Z"
        fill={colors.primary.main}
      />
      <path
        d="M19.1917 21.8036C19.39 22.5386 19.8567 23.1453 20.5217 23.5303C20.7083 23.6353 20.9067 23.717 21.1167 23.7753C20.5333 24.6503 20.3 25.747 20.5917 26.832L23.4617 37.5536C23.7183 38.522 24.3483 39.3386 25.2117 39.8403C25.795 40.1786 26.4367 40.342 27.09 40.342C27.1483 40.342 27.2067 40.3303 27.2767 40.3303C27.545 40.3186 27.8133 40.2836 28.0817 40.2136L46.1417 35.372C47.11 35.1153 47.9267 34.4853 48.4283 33.622C48.93 32.747 49.07 31.732 48.8017 30.7636L45.9317 20.042C45.885 19.8553 45.8033 19.6686 45.7217 19.4936C44.9867 17.8253 43.1317 16.892 41.3117 17.3703L39.0133 18.0003L37.5083 18.4086C37.66 17.907 37.6717 17.382 37.5317 16.8686L35.56 9.54195L35.385 8.91195C35.1867 8.17695 34.72 7.57029 34.055 7.18529C33.4017 6.80029 32.6317 6.70695 31.8967 6.90529L19.0517 10.3703C18.3167 10.5686 17.71 11.0353 17.325 11.7003C16.9517 12.3536 16.8467 13.1236 17.045 13.8586L19.1917 21.8036ZM41.7317 18.957C41.9183 18.9103 42.105 18.887 42.28 18.887C42.98 18.887 43.6333 19.237 44.03 19.8086C44.17 20.007 44.275 20.2286 44.345 20.4736L47.215 31.1953C47.3667 31.7436 47.285 32.3153 47.005 32.817C46.7133 33.307 46.2583 33.6686 45.71 33.8086L27.65 38.6386C27.3467 38.7203 27.0317 38.732 26.7283 38.6736C26.4833 38.627 26.25 38.557 26.0283 38.4286C25.5383 38.1486 25.1767 37.682 25.0367 37.1336L22.1667 26.412C21.9567 25.6186 22.225 24.8136 22.785 24.3003C23.03 24.0786 23.3217 23.892 23.6717 23.7986L39.5383 19.5403L41.7317 18.957ZM19.4717 11.9453L32.3283 8.48029C32.6433 8.39862 32.97 8.43362 33.25 8.59695C33.53 8.76029 33.7283 9.01695 33.8217 9.33195L33.9267 9.72862L35.9683 17.2886C36.05 17.6036 36.015 17.9303 35.8517 18.2103C35.6883 18.4903 35.4317 18.6886 35.1167 18.7703L22.26 22.2353C22.26 22.2353 22.26 22.2353 22.2483 22.2353C21.945 22.317 21.6183 22.2703 21.3383 22.1186C21.0583 21.9553 20.86 21.6986 20.7783 21.3836L18.6317 13.4386C18.445 12.7853 18.83 12.1203 19.4717 11.9453Z"
        fill={colors.primary.main}
      />
      <path
        d="M42.39 23.7716L40.8117 24.1919L42.2136 29.4566L43.7919 29.0364L42.39 23.7716Z"
        fill={colors.secondary.main}
      />
    </SvgIcon>
  );
};
