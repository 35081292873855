import React, { useMemo } from 'react';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';

import { Container } from 'common/app/home/container/Container';
import { CarouselSlider } from 'common/ui/carouselSlider/CarouselSlider';

import { useStyles } from './WhyHunks.styles';
import { whyHunksList } from './WhyHunks.utils';

export const WhyHunks = () => {
  const styles = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const slidesToShow = useMemo(() => {
    if (isDesktop) {
      return 4;
    } else if (isTablet) {
      return 3;
    }

    return 1;
  }, [isTablet, isDesktop]);

  return (
    <div className={styles.wrapper}>
      <Container className={styles.container}>
        <CarouselSlider
          settings={{
            slidesToShow,
            className: styles.carouselSlider,
          }}
        >
          {whyHunksList.map(({ Icon, title, subTitle }) => (
            <div key={title} className={styles.sliderItem}>
              <Icon className={styles.icon} />

              <Typography variant="h2" color="secondary" className={styles.title}>
                {title}
              </Typography>

              <Typography variant="subtitle1" align="center" className={styles.subTitle}>
                {subTitle}
              </Typography>
            </div>
          ))}
        </CarouselSlider>
      </Container>
    </div>
  );
};
