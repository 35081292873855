import { LaborIcon } from 'common/assets/icons/LaborIcon';
import { HappyClientsIcon } from 'common/assets/icons/HappyClientsIcon';
import { MealsDonatedIcon } from 'common/assets/icons/MealsDonatedIcon';
import { ReviewsIcon } from 'common/assets/icons/ReviewsIcon';

export const whyHunksList = [
  {
    Icon: LaborIcon,
    title: '15+',
    subTitle: 'Years in Business',
  },
  {
    Icon: HappyClientsIcon,
    title: '10+ Million',
    subTitle: 'Happy Clients Served',
  },
  {
    Icon: MealsDonatedIcon,
    title: '5+ Million',
    subTitle: 'Meals Donated',
  },
  {
    Icon: ReviewsIcon,
    title: '1+ Million',
    subTitle: 'Reviews, Avg 4.8 Stars (Most 5-Star Reviews in the Industry)',
  },
];
