import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: colors.grey180,
    padding: '24px 8px',

    [theme.breakpoints.up('sm')]: {
      padding: '48px 0',
      textAlign: 'center',

      ...theme.typography.h2,
    },

    [theme.breakpoints.up('md')]: {
      paddingTop: 60,
    },
  },
  highlightText: {
    color: colors.secondary.main,
  },
  serviceBoxesContainer: {
    backgroundColor: colors.basic.white,
  },
  serviceBoxes: {
    paddingBottom: 16,

    '& > *': {
      marginBottom: 16,
    },

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-around',
      gap: 24,
      marginBottom: 48,

      '& > *': {
        flex: 1,
        marginBottom: 0,
        maxWidth: 558,
      },
    },

    [theme.breakpoints.up('md')]: {
      gap: 48,
    },
  },
}));
