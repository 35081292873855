import React, { useCallback, useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';
import moment, { Moment } from 'moment';
import { CalendarPicker, Button } from '@chhjit/react-components';

import { CloseIcon } from 'common/assets/icons/CloseIcon';

import { CalendarProps } from './Calendar.types';
import { useStyles } from './Calendar.styles';

export const Calendar = ({ open, onClose, date, minDate, onDateChange }: CalendarProps) => {
  const styles = useStyles();

  const [currentDate, setCurrentState] = useState<Moment>(moment(date));

  useEffect(() => {
    setCurrentState(moment(date));
  }, [date]);

  const handleCalendarChange = useCallback((date: Moment) => {
    setCurrentState(date);
  }, []);

  const handleSelect = useCallback(() => {
    onDateChange(currentDate.toDate());
    onClose();
  }, [currentDate, onDateChange, onClose]);

  return (
    <Dialog open={open} onClose={onClose} className={styles.wrapper}>
      <DialogTitle disableTypography className={styles.title}>
        <Typography variant="h3">select date from the calendar</Typography>
        <CloseIcon onClick={onClose} className={styles.icon} />
      </DialogTitle>
      <DialogContent>
        <CalendarPicker onChange={handleCalendarChange} date={currentDate} minDate={minDate} />
      </DialogContent>

      <DialogActions className={styles.buttons}>
        <Button onClick={onClose} fullWidth size="large" buttonType="outlined">
          Back
        </Button>
        <Button onClick={handleSelect} fullWidth size="large" buttonType="twoTone" data-testid="calendarSubmit">
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};
