import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

export const useStyles = makeStyles((theme) => ({
  bgWrapper: {
    backgroundColor: colors.basic.white,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  wrapper: {
    flexGrow: 1,
    padding: '58px 16px 40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.up('sm')]: {
      maxWidth: 513,
      margin: '0 auto',
    },

    [theme.breakpoints.up('md')]: {
      maxWidth: 771,
    },
  },
  flexWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 41,

    [theme.breakpoints.up('sm')]: {
      marginBottom: 123,
    },

    [theme.breakpoints.up('md')]: {
      marginBottom: 169,
      flexDirection: 'row',
      alignItems: 'stretch',
    },
  },
  title: {
    marginBottom: 35,
    textAlign: 'center',

    [theme.breakpoints.up('sm')]: {
      marginBottom: 31,
    },

    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  icon: {
    fontSize: 160,
    marginBottom: 35,

    [theme.breakpoints.up('sm')]: {
      fontSize: 226,
      marginBottom: 143,
    },

    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      marginRight: 48,
    },
  },
  bolderText: {
    marginBottom: 16,
    textAlign: 'center',
    color: colors.grey160,
    fontWeight: 'bold',

    [theme.breakpoints.up('sm')]: {
      marginBottom: 24,
    },

    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  text: {
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  buttonsWrapper: {
    width: '100%',
    '& > *': {
      textDecoration: 'none',
    },

    '& > *:nth-child(1)': {
      marginBottom: 17,
      marginRight: -9,
    },

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center',

      '& > *:nth-child(1)': {
        order: 2,
        marginBottom: 0,
        width: 229, //+9px cause of twoTune button before element
      },
      '& > *:nth-child(2)': {
        order: 1,
        marginRight: 17,
        width: 220,
      },
    },
  },
}));
