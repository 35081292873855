import { useMemo } from 'react';
import { useSplitTreatments } from '@splitsoftware/splitio-react';

const flagNames = [
  'OnlineBooking_TrackVersion',
  'PartnerManagement_DefaultReferralSource',
  'PartnerManagement_OnlineBooking_ConfigureCategories',
  'PartnerPortal_HideTypeOfItemsForMoving',
] as const;

type Key = typeof flagNames[number];

type Toggles = {
  [key in Key]: boolean;
};

export const useFeatureFlags = () => {
  const { treatments } = useSplitTreatments({
    names: Array.from(flagNames),
  });

  const toggles = useMemo(
    () => flagNames.reduce((keys, key) => ({ ...keys, [key]: treatments[key].treatment === 'on' }), {} as Toggles),
    [treatments],
  );

  return { toggles, treatments };
};
