import { colors } from '@chhjit/react-components';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: colors.basic.white,
    paddingTop: 24,
    paddingBottom: 24,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 48,
      paddingBottom: 48,
    },
  },
  title: {
    marginBottom: 24,

    [theme.breakpoints.up('sm')]: {
      marginBottom: 37,
    },

    [theme.breakpoints.up('md')]: {
      marginBottom: 30,
      ...theme.typography.h2,
    },
  },
  imgContainer: {
    marginBottom: 24,

    [theme.breakpoints.up('sm')]: {
      marginRight: 24,
      marginBottom: 0,
    },

    [theme.breakpoints.up('md')]: {
      marginRight: 48,
    },
  },
  img: {
    width: '100%',
    objectFit: 'cover',
    objectPosition: '90% center',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',

      '& > *': {
        width: 'calc(50% - 12px)', // 12px from img marginRight 24px/2

        display: 'flex',
        flexDirection: 'column',
      },

      '& > *:nth-child(1) > img': {
        flexBasis: 0,
        flexGrow: 1,
        overflowY: 'auto',
      },
    },

    [theme.breakpoints.up('md')]: {
      '& > *': {
        width: 'calc(50% - 24px)',
      },
    },
  },
}));
