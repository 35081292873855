import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: colors.basic.white,
    paddingTop: 40,

    [theme.breakpoints.up('sm')]: {
      paddingTop: 48,
    },

    [theme.breakpoints.up('md')]: {
      paddingTop: 96,
    },
  },

  containerShadow: {
    [theme.breakpoints.up('sm')]: {
      boxShadow: '0px 0px 16px rgba(134, 145, 166, 0.16)',
    },
  },
  title: {
    marginBottom: 32,

    [theme.breakpoints.up('sm')]: {
      marginBottom: 8,
    },

    [theme.breakpoints.up('md')]: {
      marginBottom: 11,
    },
  },
  img: {
    width: '100%',
    objectFit: 'cover',
  },
  flexContainer: {
    marginBottom: 64,

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      marginBottom: 48,

      '& > *': {
        display: 'flex',
        flexDirection: 'column',
      },

      '& > *:nth-child(1)': {
        order: 2,
        width: '54%',
      },

      '& > *:nth-child(2)': {
        order: 1,
        width: '46%',

        '& > img': {
          flexBasis: 0,
          flexGrow: 1,
          overflowY: 'auto',
        },
      },
    },

    [theme.breakpoints.up('md')]: {
      marginBottom: 96,

      '& > *:nth-child(1)': {
        width: '42%',
      },

      '& > *:nth-child(2)': {
        width: '58%',
      },
    },
  },
  content: {
    [theme.breakpoints.up('sm')]: {
      padding: '48px 54.5px 68px',
    },

    [theme.breakpoints.up('md')]: {
      padding: '48px 109px 27px 49px',
    },
  },
}));
