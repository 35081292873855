import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

const imageTranslationLenght = 170;

export const useStyles = makeStyles((theme) => ({
  happyCustomerContainer: {
    backgroundColor: colors.grey10,
  },
  happyCustomerInnerWrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: 0, // remove padding left from <Container />

      '& > *:nth-child(1)': {
        width: '45%',
      },
      '& > *:nth-child(2)': {
        width: '50%',
      },

      '& > *': {
        display: 'flex',
        flexDirection: 'column',
      },

      '& > *:nth-child(1) > img': {
        flexBasis: 0,
        flexGrow: 1,
        overflowY: 'auto',
        objectFit: 'cover',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.08)',

        [theme.breakpoints.up('md')]: {
          objectPosition: '30% 50%',
        },
      },
    },

    // custom breakpoint for custom functionality (image in desktop resolution up to 1366px have to be translated left)
    '@media (min-width:1367px)': {
      paddingLeft: 40, // restore padding left from <Container />
      maxWidth: `calc(1024px + 80px + ${imageTranslationLenght}px)`, // width from <Container /> + 2x 170px cause of image translation
      paddingRight: `calc(40px + ${imageTranslationLenght}px)`, // cover 170px from one side to center the component

      '& > *:nth-child(1)': {
        width: '50%',
      },
      '& > *:nth-child(2)': {
        width: '45%',
      },
    },
  },
  wrapper: {
    padding: '24px 0 40px',

    [theme.breakpoints.up('sm')]: {
      padding: '32px 0 32px',
    },
  },
  innerWrapper: {
    margin: '0 19px',

    '& .slick-track': {
      display: 'flex',
      alignItems: 'center',
    },

    [theme.breakpoints.up('sm')]: {
      maxWidth: 525,
      margin: '0 auto',
    },
  },
  title: {
    marginBottom: 20,
    color: colors.grey190,

    '& > span': {
      color: colors.secondary.main,
    },

    [theme.breakpoints.up('sm')]: {
      marginBottom: 24,
      textAlign: 'center',
    },
  },
  sliderItem: {
    outline: 'none',
  },
  card: {
    backgroundColor: colors.basic.white,
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 36px',

    position: 'relative',
    margin: '0 calc(20px + 9px) 20px 9px',

    [theme.breakpoints.up('sm')]: {
      padding: '24px 48px',

      margin: '0 calc(20px + 16px) 20px 16px',
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: -20,
      right: -20,
      width: '100%',
      height: '100%',
      background: colors.grey40,
      zIndex: -1,
    },
  },

  quote: {
    position: 'absolute',
    top: 24,
    left: 18,
    transform: 'translateX(-50%)',
    lineHeight: '48px',

    [theme.breakpoints.up('sm')]: {
      left: 24,
    },
  },
  text: {
    fontWeight: 'bold',
    marginBottom: 20,
    color: colors.grey190,
  },
  author: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '1.5px',
  },
}));
