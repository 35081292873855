import React from 'react';
import { colors } from '@chhjit/react-components';
import { SvgIcon } from '@material-ui/core';

export const Bedroom3Icon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="48" height="48" viewBox="0 0 48 48" fill="none">
      <rect x="4.86914" y="4.86914" width="41.1304" height="41.1304" fill={colors.primary.light} />
      <rect x="2.5" y="2.5" width="20.0435" height="20.0435" fill={colors.primary.main} stroke={colors.primary.main} />
      <rect x="22.5879" y="2.5" width="21" height="20.0435" fill={colors.primary.main} stroke={colors.primary.main} />
      <rect x="2.5" y="22.5879" width="20.0435" height="21" fill={colors.primary.main} stroke={colors.primary.main} />
      <rect x="22.5879" y="22.5879" width="21" height="21" stroke={colors.primary.main} fill="none" />
    </SvgIcon>
  );
};
