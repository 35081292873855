import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

export const useStyles = makeStyles((theme) => ({
  bgWrapper: {
    backgroundColor: colors.basic.white,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  wrapper: {
    flexGrow: 1,
    padding: '20px 24px 32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.up('md')]: {
      height: 'auto',
      padding: '13vh 0 32vh',
    },
  },
  icon: {
    fontSize: 128,
  },
  title: {
    textAlign: 'center',
  },
  contact: {
    fontWeight: 'bold',
  },
  link: {
    textDecoration: 'underline',
  },
  text: {
    textAlign: 'center',
  },
  buttonsWrapper: {
    width: '100%',
    marginTop: 'auto',

    '& > *:nth-child(1)': {
      marginBottom: 17,
      marginRight: -9,
    },

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 50,

      '& > *:nth-child(1)': {
        order: 2,
        marginBottom: 0,
        width: 229, //+9px cause of twoTune button before element
      },
      '& > *:nth-child(2)': {
        order: 1,
        marginRight: 17,
        width: 220,
      },
    },
  },
}));
