import React from 'react';
import clsx from 'clsx';
import { SvgIcon, makeStyles } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

const useStyles = makeStyles({
  root: {
    fontSize: 24,
  },
});

export const ArrowRightIcon = ({ className, onClick }: { className?: string; onClick?: () => void }) => {
  const styles = useStyles();

  return (
    <SvgIcon
      className={clsx([className, styles.root])}
      onClick={onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M3 12C3 12.5523 3.44772 13 4 13L17.17 13L14.2935 15.8845C13.9048 16.2743 13.9053 16.9053 14.2945 17.2945C14.6841 17.6841 15.3159 17.6841 15.7055 17.2945L20.2929 12.7071C20.6834 12.3166 20.6834 11.6834 20.2929 11.2929L15.7055 6.70549C15.3159 6.31586 14.6841 6.31586 14.2945 6.70549C13.9053 7.09474 13.9048 7.7257 14.2935 8.11549L17.17 11L4 11C3.44771 11 3 11.4477 3 12Z"
        fill={colors.basic.black}
      />
    </SvgIcon>
  );
};
