import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

const leftSpacing = '10vw';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexGrow: 1,

    display: 'flex',
    flexDirection: 'column',
  },
  headerWrapper: {
    [theme.breakpoints.up('sm')]: {
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.08)',
    },

    [theme.breakpoints.up('md')]: {
      paddingLeft: leftSpacing,
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: `calc(${leftSpacing} + 8vw)`,
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: `calc(${leftSpacing} + 16vw)`,
    },
  },
  header: {
    color: colors.basic.black,
    padding: '32px 16.5px 0px',

    [theme.breakpoints.up('sm')]: {
      padding: '32px 24px 16px',
    },

    [theme.breakpoints.up('md')]: {
      paddingTop: '16px',
      paddingLeft: 0,
    },
  },
  gridContainer: {
    flexGrow: 1,

    display: 'flex',
    flexDirection: 'column',
    padding: '0 16.5px',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row-reverse',
      paddingRight: 0,
      paddingLeft: 24,
      height: 'calc(100% - 120px)',

      '& > *:nth-child(1)': {
        width: '71%',
      },
      '& > *:nth-child(2)': {
        width: '29%',
      },
    },

    [theme.breakpoints.up('md')]: {
      paddingLeft: leftSpacing,
      height: 'calc(100% - 104px)',

      '& > *:nth-child(1)': {
        width: '80%',
      },
      '& > *:nth-child(2)': {
        width: '20%',
      },
    },

    [theme.breakpoints.up('lg')]: {
      paddingLeft: `calc(${leftSpacing} + 8vw)`,
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: `calc(${leftSpacing} + 16vw)`,
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '32px 0 16px',
    backgroundColor: colors.grey10,

    [theme.breakpoints.up('sm')]: {
      padding: '24px 48px 46px',
    },

    [theme.breakpoints.up('md')]: {
      paddingBottom: 42,
    },
  },
  aside: {
    marginBottom: 29,
    paddingTop: 10,

    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
      paddingTop: 24,
      paddingLeft: 20,
    },

    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
    },
  },
}));
