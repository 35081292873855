import React, { useState, useRef, useCallback } from 'react';
import { Input } from '@chhjit/react-components';
import { Paper, MenuItem, Popper, Fade, ClickAwayListener } from '@material-ui/core';
import clsx from 'clsx';
import PlacesAutocomplete, { geocodeByAddress, Suggestion } from 'react-places-autocomplete';

import { CheckIcon } from 'common/assets/icons/CheckIcon';
import { BillingAddress } from 'common/api/actions/account/accountActions.types';

import { useStyles } from './AddressAutocomplete.styles';
import { AddressAutocompleteProps } from './AddressAutocomplete.types';

/**
 * Must be wrapped wrapped by GoogleScriptsController
 */
export const AddressAutocomplete = ({ label, onSelect, hasError, initialValue }: AddressAutocompleteProps) => {
  const [searchAddress, setSearchAddress] = useState(initialValue);
  const [isAddressSelected, setIsAddressSelected] = useState(!!initialValue);
  const anchorRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const styles = useStyles({ dropdownWidth: anchorRef.current?.offsetWidth });

  const handleClose = useCallback((event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
  }, []);

  const getHighlightedText = useCallback(
    (text: Suggestion['formattedSuggestion']) => {
      const parts = text.mainText.split(new RegExp(`(${searchAddress})`, 'gi'));
      const highlightIndex = parts.findIndex((part) => part.toLowerCase() === searchAddress.toLowerCase());

      return (
        <span>
          {parts.map((part, i) => (
            <span key={i} className={clsx(i === highlightIndex && styles.searchQueryMatch, styles.mainText)}>
              {part}
            </span>
          ))}
          {', '}
          {text.secondaryText}
        </span>
      );
    },
    [searchAddress, styles],
  );

  const handleSelect = useCallback(
    async (selectedAddress: string) => {
      const address = await geocodeByAddress(selectedAddress).then((results) => results[0]);
      const {
        geometry: { location },
        address_components,
      } = address;

      const fullAddress = createBillingAddress(address_components);
      setIsAddressSelected(true);
      onSelect({ location: { lat: location.lat(), lng: location.lng() }, fullAddress, inputValue: selectedAddress });
    },
    [onSelect],
  );

  return (
    <div ref={anchorRef}>
      <PlacesAutocomplete
        value={searchAddress}
        onChange={(address) => {
          setSearchAddress(address);

          if (isAddressSelected) {
            onSelect(null);
            setIsAddressSelected(false);
          }
        }}
        onSelect={(address) => {
          setSearchAddress(address);
          handleSelect(address);
          inputRef.current?.blur();
        }}
        searchOptions={{ componentRestrictions: { country: ['us', 'ca'] }, types: ['address'] }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <>
              <Input
                endAdornment={isAddressSelected && !hasError && <CheckIcon className={styles.checkIcon} />}
                variant="outlined"
                size="medium"
                label={label}
                error={hasError}
                inputRef={inputRef}
                {...getInputProps()}
                className={styles.input}
              />

              <Popper
                open={!!suggestions.length}
                anchorEl={anchorRef.current}
                placement="bottom"
                transition
                className={styles.dropdown}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps}>
                    <div>
                      <ClickAwayListener onClickAway={handleClose}>
                        <Paper elevation={1}>
                          {loading && (
                            <MenuItem className={clsx(styles.dropdownItem, styles.mainText)}>Loading...</MenuItem>
                          )}
                          {suggestions.map((suggestion) => (
                            <MenuItem
                              {...getSuggestionItemProps(suggestion)}
                              key={suggestion.placeId}
                              className={clsx(styles.dropdownItem, suggestion.active && styles.activeDropdownItem)}
                            >
                              {getHighlightedText(suggestion.formattedSuggestion)}
                            </MenuItem>
                          ))}
                        </Paper>
                      </ClickAwayListener>
                    </div>
                  </Fade>
                )}
              </Popper>
            </>
          );
        }}
      </PlacesAutocomplete>
    </div>
  );
};

const createBillingAddress = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
  const billingAddress = {} as Partial<BillingAddress>;

  billingAddress.city = '';

  addressComponents.forEach((component) => {
    if (component.types.includes('country')) {
      billingAddress.country = component.short_name;
      return;
    }
    if (component.types.includes('locality')) {
      billingAddress.city = component.short_name;
      return;
    }
    // Proper US order of address1 should be ${street_number} ${route}
    if (component.types.includes('street_number')) {
      billingAddress.address = [component.short_name, billingAddress.address].filter(Boolean).join(' ');
      return;
    }
    if (component.types.includes('route')) {
      billingAddress.address = [billingAddress.address, component.short_name].filter(Boolean).join(' ');
      return;
    }
    if (component.types.includes('postal_code')) {
      billingAddress.postal = component.short_name;
      return;
    }
    if (component.types.includes('administrative_area_level_1')) {
      billingAddress.state = component.short_name;
      return;
    }
    if (component.types.includes('administrative_area_level_3')) {
      if (!billingAddress.city) {
        billingAddress.city = component.short_name;
      }
      return;
    }
  });

  return billingAddress;
};
