import React from 'react';

import { MovingIcon } from 'common/assets/icons/MovingIcon';
import { junkRemovalRoutes, laborRoutes, movingRoutes } from 'common/routing/AppRoute';
import { JunkRemovalIcon } from 'common/assets/icons/JunkRemovalIcon';
import { LaborIcon } from 'common/assets/icons/LaborIcon';
import { CategoryIdEnum } from 'common/api/types';

export const serviceBoxesData = (
  partnerName?: string,
  disabledCategories?: number[],
): {
  id: number;
  icon: JSX.Element;
  title: string | JSX.Element;
  discount?: string | JSX.Element;
  subTitle: string;
  route: string;
}[] => {
  const isPlaceForMom = partnerName === process.env.REACT_APP_PARTNER_NAME;

  return [
    ...(!disabledCategories?.includes(CategoryIdEnum.move)
      ? [
          {
            id: CategoryIdEnum.move,
            icon: <MovingIcon />,
            title: isPlaceForMom ? 'Full Service Moving' : 'Moving',
            discount: isPlaceForMom ? <div>$50 discount</div> : undefined,
            subTitle: isPlaceForMom
              ? 'Options to meet the needs of each transition; done with a focus on Trust, Care and the utmost in Safety'
              : 'Let us do the heavy lifting so you can move into your home with ease.',
            route: movingRoutes.step5,
          },
        ]
      : []),
    ...(!disabledCategories?.includes(CategoryIdEnum.junk)
      ? [
          {
            id: CategoryIdEnum.junk,
            icon: <JunkRemovalIcon />,
            title: isPlaceForMom ? (
              'Downsizing Support'
            ) : (
              <>
                Junk removal /
                <br />
                donation pickup
              </>
            ),
            discount: isPlaceForMom ? (
              <>
                <div>full truck - $50 off</div>
                <div>1/2 Truck or more - $25 off</div>
              </>
            ) : undefined,
            subTitle: isPlaceForMom
              ? 'Mindful and compassionate donation delivery, storage, repurposing and disposal of furniture and other items that are not to be moved into the new home.'
              : 'Declutter your home in an eco-friendly way with the H.U.N.K.S.',
            route: junkRemovalRoutes.step2,
          },
        ]
      : []),
    ...(!disabledCategories?.includes(CategoryIdEnum.labor)
      ? [
          {
            id: CategoryIdEnum.labor,
            icon: <LaborIcon />,
            title: isPlaceForMom ? 'Hourly services' : 'Labor (other)',
            discount: isPlaceForMom ? <div>$25 discount</div> : undefined,
            subTitle: isPlaceForMom
              ? "Need a hand? We're happy to pack or lend some muscle to get fully set up."
              : "Need a hand? We're happy to pack or lend some muscle within your home.",
            route: laborRoutes.step2,
          },
        ]
      : []),
  ];
};
