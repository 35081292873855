import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

export const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    maxWidth: 793,
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.15)',
    marginTop: 24,

    [theme.breakpoints.up('md')]: {
      marginBottom: 16,
    },
  },
  row: {
    backgroundColor: colors.basic.white,

    '&:nth-child(even)': {
      backgroundColor: colors.grey10,
    },
  },
  divider: {
    borderBottom: `2px dashed ${colors.grey100}`,
  },
  lastRow: {
    backgroundColor: `${colors.basic.white} !important`,
  },
  cell: {
    border: 'none',
    paddingLeft: 24,
    paddingRight: 24,

    '&>*': {
      color: colors.grey190,
    },
  },
  cellTitle: {
    fontWeight: 'bold',
  },
  rightCell: {
    textAlign: 'right',
  },
  yourEstimatePrice: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '& > span': {
      ...theme.typography.h4,
      color: colors.secondary.main,
      marginLeft: 8,
    },
  },
}));
