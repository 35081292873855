import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjit/react-components';

export const useStyles = makeStyles({
  margin: {
    '&:not(:last-child)': {
      marginBottom: 8,
    },
  },
  errorText: {
    fontSize: 12,
    lineHeight: '24px',
    color: colors.grey140,
  },
  input: {
    '& > input.MuiInputBase-input.MuiInputBase-inputAdornedEnd': {
      paddingRight: 50,
    },
  },
  checkIcon: {
    fontSize: '24px',
    position: 'absolute',
    right: 19,
    top: 0,
    color: colors.functionals.success,
  },
});
