import React, { useCallback, useEffect } from 'react';
import { Typography, FormControl, useTheme, useMediaQuery } from '@material-ui/core';
import { Button } from '@chhjit/react-components';
import { useHistory, Redirect } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import clsx from 'clsx';

import { junkRemovalRoutes } from 'common/routing/AppRoute';
import { CategoryIdEnum } from 'common/api/types';
import { ButtonsGroup } from 'common/ui/buttonsGroup/ButtonsGroup';
import { useJunkRemovalContext } from 'common/hooks/useJunkRemoval/useJunkRemoval';
import { junkRemovalActions } from 'common/context/junkRemoval/JunkRemovalContextSlice';
import { useAvailability } from 'common/hooks/useAvailability/useAvailability';
import { SliderSchedulePicker } from 'common/ui/sliderSchedulePicker/SliderSchedulePicker';

import { SelectDate } from './selectDate/SelectDate';
import { useStyles } from './JunkRemovalStep3.styles';

export const JunkRemovalStep3 = () => {
  const styles = useStyles();
  const history = useHistory();
  const [{ junkRemoval }, { junkRemovalDispatch }] = useJunkRemovalContext();
  const { schedules, isCheckingAvailability, getAvailabilitySchedule } = useAvailability();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      date: junkRemoval?.step3?.date || moment().toDate(),
      schedule: junkRemoval?.step3?.schedule || null,
    },
  });
  const currentDate = watch('date');

  useEffect(() => {
    (async () => {
      if (!junkRemoval?.step1) return;

      await getAvailabilitySchedule({
        postal: junkRemoval.step1.fullAddress.postal,
        categoryId: CategoryIdEnum.junk,
        jobDate: currentDate,
      });
    })();
  }, [junkRemoval?.step1, currentDate, getAvailabilitySchedule]);

  const handleSubmitCallback = useCallback(
    (values) => {
      junkRemovalDispatch(junkRemovalActions.setStep3({ date: values.date, schedule: values.schedule }));
      history.push(junkRemovalRoutes.step4);
    },
    [junkRemovalDispatch, history],
  );

  if (!junkRemoval?.step2) {
    return <Redirect to={junkRemovalRoutes.step2} />;
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitCallback)} className={styles.wrapper}>
      <div className={styles.content}>
        <Typography variant="h4" className={styles.margin}>
          3. Job date
        </Typography>

        <Typography variant="body1" className={styles.margin}>
          Select a date to check our availability
        </Typography>

        <FormControl fullWidth className={styles.field}>
          <Controller
            control={control}
            name="date"
            render={({ field: { name, onChange, onBlur } }) => {
              return (
                <SelectDate
                  name={name}
                  value={currentDate}
                  onSelect={(date) => {
                    onChange(date);
                    reset({ schedule: null });
                    onBlur();
                  }}
                />
              );
            }}
          />
        </FormControl>

        {isDesktop && (
          <Typography variant="h5" className={styles.h5}>
            We can be at your place between:
          </Typography>
        )}

        <Typography variant="body1" className={clsx([errors.schedule && styles.error, styles.body1])}>
          Select the arrival window that works best for you for our HUNKS to arrive
        </Typography>

        <Controller
          control={control}
          name="schedule"
          defaultValue={null}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <SliderSchedulePicker
              value={value}
              onChange={onChange}
              initialPeriod={junkRemoval?.step3?.schedule.period}
              isLoading={isCheckingAvailability}
              schedules={schedules}
            />
          )}
        />
      </div>

      <ButtonsGroup className={styles.buttonsWrapper}>
        <Button onClick={() => history.push(junkRemovalRoutes.step2)} fullWidth size="large" buttonType="outlined">
          Back
        </Button>
        <Button type="submit" disabled={!isValid || isCheckingAvailability} fullWidth size="large" buttonType="twoTone">
          Next
        </Button>
      </ButtonsGroup>
    </form>
  );
};
